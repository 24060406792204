<app-navbar></app-navbar>

<div *ngIf="isLoading" class="loader-overlay">
  <div class="loader"></div>
</div>

<section class="recommendations">
  <div class="container feature-container">
    <div class="row installation-heading search-list-nota-nota row feature-row" >
    <div class="header">
      <h2> DONA recommendations to you with the best prices </h2> 
      <hr>
  </div>
<div (click)="userprofile()" class="containers">
  <!-- <canvas #collageCanvas width="180" height="180"></canvas> -->
    <!-- <div #tooltip class="tooltip"></div> -->
    <div class="text p-4">
      <h4>Your Scent Profile</h4>
      <p>
          You love 
          <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 0">
              <span class="words">{{ mostFrequentMainNotes[0] }}</span>
          </span>
          <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 1">
              and
              <span class="words">{{ mostFrequentMainNotes[1] }}</span>
          </span>
          <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 1">
              fragrance families
          </span>
          <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length <= 1">
              fragrance family
          </span>
          with
          <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 0">
              <span class="words">{{ mostFrequentSubNotes[0] }}</span>
          </span>
          <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 1">
              and
              <span class="words">{{ mostFrequentSubNotes[1] }}</span>
          </span>
          <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length <= 1"></span>
      </p>
    </div>
</div>
</div>

    <div dir="ltr" class="top-recommendations">
      <div class="our-recommended-nota-nota"  *ngFor="let data of recommendationsCardData.slice(0, 3);let i = index"> 
        <img *ngIf="isLoggedIn" (click)="addFavorites(data)" class="fav-img-rec m-lg-2 mobile-fav" 
        [src]="'../../../../assets/images/love.png'" />
        <div class="perfume-pic">
          <img class="perfume-img"  
            src="{{data['product.image_url']}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;" alt="Perfume2" />
        </div>
        <div class="perfume-des">
        <div class="nota-info">
          <div class="nota-info-2">
          <div class="nota-nota-tooltip-hover"  [ngClass]=" data['product.name'] ? '' : 'widget-pointer'">
            <h6 class="perfume-title"  >{{data["product.name"]}}</h6>
          </div>
          <div class="nota-nota-tooltip-hover">
            <p class="perfume-subtitle">{{data["product.brand"]}}</p>
          </div>  
        </div>
        <div class="nota-nota-tooltip-hover">
          <h6><a>{{data["product.store"]}}</a></h6>
        </div>
        
        </div>
      <div >
    
        <div *ngFor="let price of data.priceData">
          <hr> 
          <div class="perfume-dess">
          <div class="nota-info-2">
            <div class="nota-nota-tooltip-hover"  [ngClass]=" price.name ? '' : 'widget-pointer'">
              <h6 class="perfume-title"  >{{price.name}}</h6>
            </div>
          </div>
          <div class="nota-nota-tooltip-hover nota-space">
            <div class="two-price">
              <p class="perfume-subtitle price prises with-sale" *ngIf="price && price.sale && price.sale !== price.price">
                  <span class="original-sale">{{ extractNumber(price.price) | number: '1.2-2' }} SAR</span>
                  <span class="original-price">{{ extractNumber(price.sale) | number: '1.2-2' }} SAR</span>
              </p>
              <p class="perfume-subtitle price price-without-sale" *ngIf="price && (!price.sale || price.sale === price.price)">
                  {{ extractNumber(price.price) | number: '1.2-2' }} SAR
              </p>
          </div>
          </div>
        </div>
        <div class="perfume-dess">
          <h6><img class=""  
            src="{{price?.logo}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;"  alt="Perfume2" />
          </h6>
          <button  class="add-to-cart btn primary pl-25 pr-25" (click)="Perfumeinfo(data,price?._id)"> <h6>Shop Now </h6> </button>
        </div>
        </div>
        <hr>
        
        
             
        </div>
      </div>
      <button  class="more btn primary pl-25 pr-25" *ngIf="data.priceData?.length > 2" (click)="otherpricesepage(data['product._id'])" > <h6>Discover other prices</h6> </button>
        
    </div>
  </div>
    <div *ngIf="!viewmore" dir="ltr" class="top-recommendations">
    <div  class="our-recommended-nota-nota"  *ngFor="let data of recommendationsCardData.slice(3); let i = index"> 
      <img *ngIf="isLoggedIn" (click)="addFavorites(data)" class="fav-img-rec m-lg-2 mobile-fav" 
      [src]="'../../../../assets/images/love.png'" />
      <div class="perfume-pic">
        <img class="perfume-img"  
          src="{{data['product.image_url']}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;" alt="Perfume2" />
      </div>
      <div class="perfume-des">
      <div class="nota-info">
        <div class="nota-info-2">
        <div class="nota-nota-tooltip-hover"  [ngClass]=" data['product.name'] ? '' : 'widget-pointer'">
          <h6 class="perfume-title"  >{{data["product.name"]}}</h6>
        </div>
        <div class="nota-nota-tooltip-hover">
          <p class="perfume-subtitle">{{data["product.brand"]}}</p>
        </div>
      </div>
      <div class="nota-nota-tooltip-hover">
        <h6><a>{{data["product.store"]}}</a></h6>
      </div>
      </div>
    <div >
      <div *ngFor="let price of data.priceData">
        <hr> 
        <div class="perfume-dess">
        <div class="nota-info-2">
          <div class="nota-nota-tooltip-hover"  [ngClass]=" price.name ? '' : 'widget-pointer'">
            <h6 class="perfume-title"  >{{price.name}}</h6>
          </div>
        </div>
        <div class="nota-nota-tooltip-hover nota-space">
          <div class="two-price">
            <p class="perfume-subtitle price prises with-sale" *ngIf="price && price.sale && price.sale !== price.price">
                <span class="original-sale">{{ extractNumber(price.price) | number: '1.2-2' }} SAR</span>
                <span class="original-price">{{ extractNumber(price.sale) | number: '1.2-2' }} SAR</span>
            </p>
            <p class="perfume-subtitle price price-without-sale" *ngIf="price && (!price.sale || price.sale === price.price)">
                {{ extractNumber(price.price) | number: '1.2-2' }} SAR
            </p>
        </div>
        </div>
      </div>
      <div class="perfume-dess">
        <h6><img class=""  
          src="{{price?.logo}}"   onerror="this.src=`https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png`;"  alt="Perfume2" />
          <!-- <a>{{price?.store}}</a> -->
        </h6>
        <button  class="add-to-cart btn primary pl-25 pr-25" (click)="Perfumeinfo(data,price?._id)"> Get it now  </button>
      </div>
      </div>
      <hr>
      
      
           
      </div>
    </div>
    <button  class="more btn primary pl-25 pr-25"  *ngIf="data.priceData?.length > 2" (click)="otherpricesepage(data['product._id'])" > Discover other prices </button>
      
  </div>
  
</div> 
<div class="button-container">
  <div class="more-less mb-50">
    <!-- Show 'view more' button if `viewmore` is true -->
    <button class="view btn primary pl-25 pr-25 mt-50" *ngIf="viewmore && !viewless" (click)="nextPage()">view more</button>
    <!-- Show 'view less' button if `viewless` is true -->
    <button class="view btn primary pl-25 pr-25 mt-50" *ngIf="viewless && !viewmore" (click)="viewlee()">view less</button>
  </div>
  
  </div>
    <div class="width-100" *ngIf="showNUllscreen">
      <div class="recommended-null">
        <div class="no-perfume-found">
          <h5 class="no-title">Oops... no recommendations found</h5>
          <p class="no-text">Seems like this perfume is not in our list yet.</p>
        </div>
      </div>
    </div>

    <div class="spinner-nota-nota" *ngIf="loaderRecommdation">
      <div class="loader-nota-nota" ></div>
      <div class="loader-overlay-nota-nota"></div>
    </div>
  
    </div>




<app-wishlist *ngIf="showWishlistContent"></app-wishlist>
<app-footer></app-footer>
<!-- <app-chat-widght></app-chat-widght> -->
</section>

  <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->
