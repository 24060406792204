<div class="login-modal-nota-nota after-login-modal-transparent"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()" src="/assets/icons/close-icon.svg" alt="Close icon" />
      <h5>Contact Us</h5>
    </div>
    <hr>
    <div class="login-body">
      <div class="advisor-slide-nota-nota">
        <form  [formGroup]="contactform" (submit)="contactform.valid && contactforms()">
          <!-- <h6>Choose the business type that best mirrors the core operations of your company. <span class="title">*</span></h6> -->
          <form [formGroup]="contactform" (submit)="contactform.valid && contactforms()">  
            <label for="floatingInput">Email  (*)</label>
            <div class="form-floating  {{(contactform.controls['email'].dirty && contactform.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
                <div class="feedback"  *ngIf="contactform.controls['email'].dirty && contactform.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="contactform.controls['email'].dirty && contactform.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
            </div>
          <label for="floatingInput"> Website </label>
          <div class="form-floating ">
            <input type="text" class="form-control" formControlName="website" placeholder="Website">
              <div class="feedback"  *ngIf="contactform.controls['website'].dirty && contactform.controls['website'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
                 <div class="feedback "  *ngIf="contactform.controls['website'].dirty && contactform.controls['website'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
          </div>
          <label for="floatingInput"> Company name </label>
          <div class="form-floating  ">
            <input type="text" class="form-control" formControlName="Company_name" placeholder="Company name">
              <div class="feedback"  *ngIf="contactform.controls['Company_name'].dirty && contactform.controls['Company_name'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
                 <div class="feedback "  *ngIf="contactform.controls['Company_name'].dirty && contactform.controls['Company_name'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
          </div>
          <label for="floatingInput"> First name (*)  </label>
          <div class="form-floating  ">
            <input type="text" class="form-control" formControlName="first_name" placeholder="First name">
              <div class="feedback"  *ngIf="contactform.controls['first_name'].dirty && contactform.controls['first_name'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
                 <div class="feedback "  *ngIf="contactform.controls['first_name'].dirty && contactform.controls['first_name'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
          </div>
          <label for="floatingInput"> Last name  (*) </label>
          <div class="form-floating  ">
            <input type="text" class="form-control" formControlName="last_name" placeholder="Last_name">
              <div class="feedback"  *ngIf="contactform.controls['last_name'].dirty && contactform.controls['last_name'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
                 <div class="feedback "  *ngIf="contactform.controls['last_name'].dirty && contactform.controls['last_name'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
          </div>
          <label>Message (*)</label>
          <div class="form-floating">
            <input type="tel" pattern="[0-9]{10,15}" class="form-control" formControlName="message" placeholder="your message">
  <!--              <div class="feedback"  *ngIf="contactform.controls['message'].dirty && contactform.controls['message'].hasError('pattern')">{{validationMessage.INVALID_PHONE}}</div>-->
  <!--                 <div class="feedback "  *ngIf="contactform.controls['message'].dirty && contactform.controls['message'].hasError('required')">{{validationMessage.PHONE_REQUIRED}}</div>-->
          </div>
  
          </form>
          <button type="submit" class="btn primary pl-25 pr-25 btn-sub mb-20">send</button>
        </form>

      </div>
  </div>
</div>
