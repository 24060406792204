import { Component, Input, OnInit } from '@angular/core';
import { delete_reason } from '../../../enum/constraints';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../../core/auth.service';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  delete_reason = delete_reason.sort();
  validationMessage = VALIDATION_MESSAGE;
  userid = localStorage.getItem("user_id");
  deleteform: any;
  product_id: any;
  isLoggedIn: boolean;
  @Input() data: any;
  contactform: any;
  constructor(
    private readonly formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    public service: DashboardServiceService,
    private readonly AuthService: AuthService
    ) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }
    formInitialization() {
      this.contactform = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        message: ['', Validators.required],
        website: [''],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        Company_name: [''],
      });
    }
  ngOnInit(): void {
    this.formInitialization();
  }
  
  contactforms() {
    if (this.isLoggedIn) {
    let data = {
      user_id: this.userid,
      email: this.contactform.value.email,
      message: this.contactform.value.message,
      website: this.contactform.value.website,
      first_name: this.contactform.value.first_name,
      last_name: this.contactform.value.last_name,
      Company_name: this.contactform.value.Company_name,
    };
    this.service.partner(data)
    .subscribe((response) => {
      // this.isSubmissionSuccessful = true;
      // this.partnercontent = false;
    },(error) => {
    })
  }else if(!this.isLoggedIn){
    let data = {
      email: this.contactform.value.email,
      message: this.contactform.value.message,
      website: this.contactform.value.website,
      first_name: this.contactform.value.first_name,
      last_name: this.contactform.value.last_name,
      Company_name: this.contactform.value.Company_name,
    };
    this.service.partner(data)
    .subscribe((response) => {
      // this.isSubmissionSuccessful = true;
      // this.partnercontent = false;
    },(error) => {
    })
  }
}

}
