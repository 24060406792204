import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-product-not-found',
  templateUrl: './product-not-found.component.html',
  styleUrls: ['./product-not-found.component.scss']
})
export class ProductNotFoundComponent implements OnInit {
  constructor(
    private router: Router
    ) { }
  ngOnInit(): void {
  }
   home(){
    this.router.navigateByUrl(`/`);
   }
}
