<app-navbar></app-navbar>
<section id="installation" lang="ar">
  <div class="installation ">
    <div class="installation-container">
      <div class="row installation-heading search-list-nota-nota row feature-row">
        <div>
        <form class=" container">
          <div class="form-group mb-20 container">
            <p *ngIf="clickperfume">Pick at least 3 favorites to unlock your match!</p>
            <h3>ابحث عن عطورك المفضلة</h3>
            <input dir="ltr" type="search" placeholder="ابحث باسم العطر" [formControl]="searchInputControl" />
          </div>
         <div class="div-perfume" *ngIf="perfumediv">
          <h3>اضف <span class="highlight"> من 3 الى 5</span> عطورك المفضلة </h3>
          <!-- <h3 #alertMessage class="alert-message" *ngIf="more5"> يمكنك فقط اضافة 5 عطور </h3> -->
          <div class="default-pic-wrp-mobile">
          </div>
          <hr/>
          <div dir="ltr" class="perfume-listing container">
            <div dir="ltr" class="perfume-listing-wrap "
              *ngFor="let data of searchData" (click)="add(data)" >
              <div dir="ltr" class="perfume-info">
                <div class="perfume-pic">
                  <img class="right-arrow" src="{{data?.image_url}}"  onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume pic" />
                </div>
                <div class="perfume-des">
                  <h6 class="perfume-title"  >{{data?.name_ar}} </h6>
                  <p class="perfume-text">{{data?.brand_ar}}</p>
                </div>
              </div>
              <div class="plus-icon">
                <button class="btn add-to-cart pl-25 pr-25" [ngClass]="idGetter(data?._id)? 'disableClass' : '' ">
                  {{ idGetter(data?._id) ? 'مضاف' : ' اضافة' }}
                </button>
            </div>
            
            </div>  
            <div class="spinner-nota-nota" *ngIf="loader">
              <div class="loader-nota-nota"></div>
              <div class="loader-overlay-nota-nota"></div>
            </div>

            <div class="width-100">
              <div class="recommended-null" *ngIf="!loader">
                <div class="no-perfume-found request-this-perfume" *ngIf="searchData.length ===0 && noDataFirstTime">
                  <h5 class="no-title">عفوًا.. لم نجد ماتبحث عنه</h5>
                  <p class="no-text">يبدو أن هذا العطر ليس في قائمتنا بعد.</p>
                  <button class="no-btn btn primary pl-25 pr-25"
                  (click)="openSuggest()">أقترح العطور</button>
                </div>
              </div>
            </div>
            
          </div>
          <div class="button-container">
            <button class="mb-50" (click)="nextscreenfirst(showProductonFirstSCreen)" 
                  [disabled]="!(showProductonFirstSCreen.length > 2)"
                  [ngClass]="{'black-button': showProductonFirstSCreen.length > 2, 'disabled-button': showProductonFirstSCreen.length <= 2}">
            التالي
            </button>
          </div>
        </div>

         <!-- <app-footer></app-footer> -->
        </form>
      </div>
  
      </div>
      <div class="row installation-heading installation-heading-arabic search-list-nota-nota row feature-row" >

      <div class="common-search perfume-search col-md-3 feature-left container">
        <!-- <owl-carousel-o [options]="customOptions"> -->
      
          <ng-template carouselSlide>
            <div class="card-wrap" *ngFor="let data of showProductonFirstSCreen;let i = index">                
              <div class="perfume-item">
                <div class="perfume-pic">
                  <img class="perfume-img" src="{{data.image_url}}"    onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume" />
                  <img class="minus-icon"
                    src="/assets/icons/close-icon.svg" alt="Remove"
                    (click)="remove(data._id, true)" />
                </div>
                <div class="perfume-des">
                  <div class="nota-nota-tooltip-hover">
                    <h6 class="perfume-title">{{data.name}}</h6>
                  </div>
                  <div class="nota-nota-tooltip-hover">
                    <p class="perfume-subtitle">{{data.brand}}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        <!-- </owl-carousel-o> -->
      </div>
  </div>

  
  


    </div>
  </div>

</section>



<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="previousPerfume">
  <div class="login-page">
    <img  (click)="closemodel()"
    src="/assets/icons/close-icon.svg" alt="Close icon" />
    <h2> خمسة عطور مضافة</h2>
     <h6> يمكنك فقط اضافة خمسة عطور , من فضلك قم بحذف واحد </h6>
     <hr>
    <div class="login-body">
        <div dir="ltr" class="top-recommendations row mt-3 mb-5">
              <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of showProductonFirstSCreen;let i = index">
                <div class="" >
                <div class="perfume-pic perfume-pic-store">
                    <img class="perfume-img info-img perfume-img-info"   
                    src="{{data.image_url}}" 
                    alt="Perfume2" />
                  </div>
                <div class="perfume-des">
                  <div class="nota-info-2">
                    <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                      <span class="perfume-title"  >{{data["name_ar"]}}</span>
                    </div>
                    <div class="nota-nota-tooltip-hover">
                      <span class="perfume-subtitle">{{data["brand_ar"]}}</span>
                    </div>
    <hr>
  
                </div>
                </div>
                <div class="nota-info">
                  <button  class="removebtn btn primary pl-25 pr-25" (click)="removeFromFavorites(data._id)"> ازالة العطر</button>
                </div>
              </div>
              
              </div>   

      </div>
</div>
</div>
</div>


<!-- 
  <app-chat-widght></app-chat-widght> -->
  <app-footer></app-footer>


<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
