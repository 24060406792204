<app-navbar></app-navbar>
<div class="product-page" lang="ar">
  <aside class="sidebar">
    <h6> الفلتر </h6>
      <!-- <p (click)="clearClick()">Rest</p> -->
    <ul >
      <li>
        <div class="filter-options" (click)="handleClick('Discount')">
          <img class="click" alt="icon" [src]="isClicked === 'Discount' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>تخفيضات</p>
        </div>
      </li>
      <li>
        <div class="filter-options" (click)="handleClick('LowPrices')">
          <img class="click" alt="icon" [src]="isClicked === 'LowPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>السعر (من الاقل الى الاعلى)</p>
        </div>
      </li>
      <li>
        <div class="filter-options" (click)="handleClick('HighPrices')">
          <img class="click" alt="icon" [src]="isClicked === 'HighPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>السعر (من الاعلى الى الاقل)</p>
        </div>
      </li>
      <li>
      </li>
      </ul>
  </aside>
<section class="info-perfume">
  <div class="container  col-lg-6 feature-container padme-perfume">
    <div class="row installation-heading search-list-nota-nota row feature-row">
      <div class="" >
            <div class="padme">
              <div class="column col1">

                <img class="perfume-img info-img perfume-img-info  left"   
                src="{{this.image }}"   
                alt="Perfume2" />
                </div>
                <div class="column col2 m-3">
                  <h2>{{this.name }}</h2>
                  <h3 class="h3-info">{{ this.brand }}</h3>
                  <div class="two-price">
                    <p class="perfume-subtitle price prises with-sale" *ngIf="sale">
                      <span class="original-sale">{{ price }} SAR</span>
                      <span class="original-price">{{ sale }} SAR</span>  
                    </p>
                    <p class="perfume-subtitle price price-without-sale" *ngIf="!sale">
                      <span class="price">{{ price }} SAR</span>
                    </p>
                  </div>
                  
                 <div class="d-flex">
                  <button class="btn primary pl-25 pr-25 mb-10 height40" (click)="clickuser($event)"  #specificButton>
                  <a id="productLink" href="{{this.link}}" target="_blank">احصل عليه الان من <span id="websiteName"></span></a>
                </button>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
        <div class="container  col-lg-8 feature-container padme-perfume">
          <hr>
          <div class="filter-btn">
            <div>
              <h3 class="mobile-filter">قارن الأسعار واختر الأفضل</h3>
            </div>
          </div>
          <div dir="rtl" class="top-recommendations row">
            <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of priceDatanew;let i = index">
            <div class="" >
              <div class="perfume-pic perfume-pic-store">
                <img class="perfume-img info-img perfume-img-info"   
                src="{{this.image }}"   
                alt="Perfume2" />
              </div>
              <div class="perfume-des">
                <div class="nota-info">
                  <div class="nota-info-2">
                    <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                      <h6 class="perfume-title"  >{{data["name"]}}</h6>
                    </div>              
                </div>
                <div class="nota-nota-tooltip-hover dev-prices">
                <div class="two-price">
                  <p class="perfume-subtitle price prises with-sale" *ngIf="price && data['sale'] && data['sale'] !== data['price']">
                    <span class="original-sale">{{ extractNumber(data['price']) | number: '1.2-2' }} SAR</span>
                    <span class="original-price">{{ extractNumber(data['sale']) | number: '1.2-2' }} SAR</span>
                  </p>
                  <p class="perfume-subtitle price price-without-sale" *ngIf="price && (!data['sale'] || data['sale'] === data['price'])">
                    {{ extractNumber(data['price']) | number: '1.2-2' }} SAR
                  </p>
                </div>
                </div>
                </div>
                </div>
                <div class="nota-info">
                  <div class="nota-nota-tooltip-hover store">
                    <a><img class=""  
                        src="{{data['logo']}}"  alt="Perfume2" /></a>
                    </div>
                  <button  class="add-to-cart btn primary pl-25 pr-25" (click)="Perfumeinfo(data,data?._id)"><a target="_blank" href="{{data.link}}"> احصل عيه الان</a></button>
                </div>
            </div>
              </div>
            </div>
          </div>
    

          <!-- <app-chat-widght></app-chat-widght> -->
          <app-footer></app-footer>

<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->