import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/error.interceptor';
import { HttpService } from './core/http.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoaderComponent } from './shared/loader/loader.component';
import { RouterModule } from '@angular/router';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    // ContactComponent,
    // NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(environment.toastrConfig),
    BsDropdownModule,
    ModalModule.forRoot(),
    RouterModule.forRoot([]),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-WZRF824S'
    })
  ],
  providers: [
    GoogleTagManagerService,
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) { }

  ngDoBootstrap() {    
    // Custom bootstrap logic if needed
  }
}
