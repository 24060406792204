
<div lang="ar">
<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="login"> 
  <div class="login-page">
    <div class="login-body">
      <img class="login-head" (click)="close()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
      <h2>تسجيل الدخول</h2>
      <hr>
     <!-- <h6 *ngIf="firstlogin">اهلا بعودتك الى حسابك</h6> -->
     <h6 *ngIf="error" class="error">كلمة المرور او بريدك الالكتروني خاطئ</h6>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="loginForm" (submit)="loginForm.valid && loginform()">
          <label for="floatingInput">البريد الالكتروني</label>
          <div class="form-floating  {{(loginForm.controls['email'].dirty && loginForm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
                <div class="feedback"  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL_AR}}</div>
                   <div class="feedback "  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED_AR}}</div>
            </div>
            <label for="floatingPassword">كلمة المرور</label>
            <div class="form-floating {{(loginForm.controls['password'].dirty && loginForm.controls['password'].invalid)?'invalid-form-group':''}}">
              <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password">
              <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
              <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
              <div class="feedback"
              *ngIf="loginForm.controls['password'].dirty &&
              loginForm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED_AR}}</div>
          </div>
            <!-- <input dir="ltr" class="floating-input" placeholder="email" type="email" formControlName="email" required> -->
        
          <!-- <a  [href]="['/admin/forget-password']" ><h6 >Forgotten your password ?</h6></a> -->
          <h6 class="nav-title"> ليس لديك حساب في دونا ؟ <a (click)="createAccount()"> تسجيل</a></h6>
          <button type="submit" class="btn primary pl-25 pr-25 btn-login" 
             >تسحيل دخول</button>
             <p (click)="Forgotten()">هل نسيت كلمة المرور؟</p>
        </form>
      </div>
    </div>
  </div>
 </div>

<div  *ngIf="createAcc">
  <div class="login-modal-nota-nota after-login-modal-transparent"> 
   <div class="login-page">
     <div class="login-head mb-20">
       <img  (click)="closeAccount()"
         src="/assets/icons/close-icon.svg" alt="Close icon" />
     </div>
     <div class="login-body">
      <h2> قم بانشاء حسابك الان</h2>
       <h6> التسجيل في دونا مجانا وسريع</h6>
       <hr>
       <div class="advisor-slide-nota-nota">
        <div *ngIf="!existsEmail" class="exists-email">
          <p>عنوان البريد الإلكترونى هذا مسجل بالفعل<a (click)="showlogin()">تسجيل دخول</a></p>
        </div>
         <form [formGroup]="CreateuserFrm" (submit)="CreateuserFrm.valid && Createuserform()">
          <label for="floatingInput">بريدك الالكتروني</label>
          <div class="form-floating  {{(CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].invalid)?'invalid-form-group':''}}">
            <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <div class="feedback"  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL_AR}}</div>
                 <div class="feedback "  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED_AR}}</div>
          </div>
          <label for="floatingPassword">كلمة المرور</label>
          <div class="form-floating ">
            <input [type]="password1" formControlName="password"  class="form-control" placeholder="كلمة المرور">
            <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
            <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
            <div class="feedback"
            *ngIf="CreateuserFrm.controls['password'].dirty &&
            CreateuserFrm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED_AR}}</div>
        </div>
        <label for="floatingInput">الاسم</label>
        <div class="form-floating  ">
          <input type="text" class="form-control"  formControlName="full_name" placeholder="ادخل الاسم الخاص بك">
            <div class="feedback"  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('pattern')">{{validationMessage.INVALID_NAME_AR}}</div>
               <div class="feedback "  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('required')">{{validationMessage.NAME_REQUIRED_AR}}</div>
        </div>
        <label for="floatingInput">رقم الهاتف</label>
        <div class="form-floating ">
          <input type="phone"   pattern="[0-9]*" class="form-control" formControlName="phone" placeholder="ادخل رقم الهاتف الخاص بك">
            <div class="feedback"  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('pattern')">{{validationMessage.INVALID_PHONE_AR}}</div>
               <div class="feedback "  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('required')">{{validationMessage.PHONE_REQUIRED_AR}}</div>
        </div>
            <button type="submit" class="btn primary pl-25 pr-25 btn-login"
            >انشاء حساب</button>
            <h6 class="nav-title"> لديك حساب في دونا بالفعل ؟ <a (click)="showlogin()">تسجيل الدخول</a></h6>
        </form>
       </div>
     </div>
   </div>
  </div>
</div>


<div class="both">   
  <div class="box-navbar">
    <button class="btn arabic-box"><a class="text-with-underline" [href]="['/']"> English </a><img class="mr-10" src="../../../../assets/icons/arabic.svg"></button>
    <button class="sign-in" *ngIf="!isLoggedIn" ><div class="nav-link" data-target="#login" data-toggle="modal" (click)="createAccount()">
    انشاء حساب<img class="mr-10" src="../../../../assets/icons/user.svg" width="auto" height="auto" alt="user">
    </div></button>
    <button data-toggle="modal" data-target="#login" class="sign-in" *ngIf="isLoggedIn" ><div class="nav-link" data-target="#login" data-toggle="modal" (click)="signout()">
      تسجيل خروج<img class="mr-10" src="../../../../assets/icons/user.svg" width="auto" height="auto" alt="user">
    </div></button>
  </div>
  <div class="navbar-brand">
  <a  href="/">
    <img class="logo mb-2" [src]="'../../../../assets/images/logo-without-bg.png'">
    <!-- <p class="mb-5">Perfect Scents,  Anytime</p> -->
  </a>  
</div>
<div class="menu">
  <div><a *ngIf="isLoggedIn" [href]="['ar/user-profile']">ملفي العطري</a>
    <span *ngIf="!isLoggedIn" (click)="showlogin()">ملفي العطري</span>
  </div>
  <div><a *ngIf="isLoggedIn" [href]="['ar/wishlist']">قائمة الامنيات</a>
    <span *ngIf="!isLoggedIn" (click)="showlogin()">قائمة الامنيات</span>
  </div>
  <div *ngIf="isLoggedIn" (click)="editProfile()">تعديل معلوماتي</div>
  <div *ngIf="!isLoggedIn" (click)="showlogin()">تعديل معلوماتي</div>
</div>

</div>

<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="edit"> 
  <div class="edit-page">
    <div class="login-head mb-20">
      <img class="close-icon" (click)="closeform()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h3 class="login-title"> تعديل معلوماتي </h3>
      <hr>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="editForm" (submit)="editForm.valid && updateUserData()">
          <label for="floatingInput">اسمك  </label>
          <div class="form-floating">
                  <input type="email" class="form-control" placeholder="{{user_name}}" type="text" formControlName="full_name">
                </div>
                <label for="floatingInput">بريدك الالكتروني </label>
                <div class="form-floating">
                  <input type="email" class="form-control" placeholder="{{user_email}}" type="email" formControlName="email">
                </div>
                <div class="form-floating" *ngIf="user_phone">
                <label for="floatingInput">رقم هاتفك </label>
                  <input type="email" class="form-control" placeholder="{{user_phone}}" type="phone" formControlName="phone">
                </div>
            <button type="submit" class="btn primary save pl-25 pr-25 btn-login" 
             >حفظ التعديلات</button>
             <button (click)="delleteaccount(this.userID)" type="submit" class="btn primary delete-button pl-25 pr-25 btn-login" 
             >حذف الحساب</button>
        </form>
      </div>
    </div>
  </div>
 </div> 


 <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="forgetpass"> 
  <div class="login-page">

    <div class="login-head mb-20">
      <img  (click)="closepass()"
      src="/assets/icons/close-icon.svg" alt="أيقونة إغلاق" />
    </div>
    <div class="login-body">
      <h6 *ngIf="error" class="error">عنوان البريد الإلكتروني أو كلمة المرور غير صحيحة</h6>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="forgetPasswordForm" (submit)="forgetPasswordForm.valid && forgetPassword()">
          <div class="sign-in-heading">
              <h3 class="">هل نسيت كلمة المرور؟</h3>
              <p class="auth-short-des">أدخل عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل لك بريدًا إلكترونيًا يحتوي على تعليمات لإعادة تعيين كلمة المرور.</p>
          </div>
          <hr>
          <div class="form-floating {{(forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">عنوان البريد الإلكتروني</label>
              <div class="feedback" *ngIf="forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
              <div class="feedback" *ngIf="forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].hasError('required')">{{validationMessage.REQUIRED}}</div>
            </div>
          <div class="auth-btn">
              <button class="btn primary w-100" type="submit" [disabled]='forgetPasswordForm.invalid'>إرسال التعليمات</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
<!-- 
<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="changePassword">
  <div class="login-page">
    <div class="col-md-4 container">
      <form [formGroup]="changePasswordForm" (submit)="changePasswordForm.valid && changePass()">
        <div class="sign-in-heading">
            <h3 class="">إعادة تعيين كلمة المرور</h3>
            <p class="auth-short-des w-100">يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور السابقة المستخدمة.</p>
        </div>
        <div class="form-floating">
            <input type="number" class="form-control" formControlName="code" placeholder="رمز OTP">
            <label for="floatingInput">رمز OTP</label>
            <div class="feedback"
            *ngIf="changePasswordForm.controls['code'].dirty &&
            changePasswordForm.controls['code'].hasError('required')">{{validationMessage.REQUIRED }}</div>
        </div>
        <div class="form-floating">
            <input [type]="password2" class="form-control" formControlName="new_password" placeholder="كلمة مرور جديدة">
            <a class="show-password"><img *ngIf ="show1" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="عرض كلمة المرور" (click)="Click('password2')"></a>
            <a class="show-password"><img *ngIf ="!show1" src="../../../../assets/icons/hide-eye-icon.svg" alt="إخفاء كلمة المرور" (click)="Click('password2')"></a>
            <label for="floatingInput">كلمة المرور الجديدة</label>
            <div class="feedback"
            *ngIf="changePasswordForm.controls['new_password'].dirty &&
            changePasswordForm.controls['new_password'].hasError('required')">{{validationMessage.NEW_PASSWORD_REQUIRED}}</div>
        </div>
        <div class="form-floating">
            <input [type]="password3" class="form-control" formControlName="confirm_password" placeholder="تأكيد كلمة المرور الجديدة">
            <a class="show-password"><img *ngIf ="show2" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="عرض كلمة المرور" (click)="Click('password3')"></a>
            <a class="show-password"><img *ngIf ="!show2" src="../../../../assets/icons/hide-eye-icon.svg" alt="إخفاء كلمة المرور" (click)="Click('password3')"></a>
            <label for="floatingInput">تأكيد كلمة المرور الجديدة</label>
            <br>
            <div *ngIf="checkPassSame() && !changePasswordForm.controls['confirm_password'].hasError('required')">
                <p class="feedback">{{passmsg}} </p>
            </div>
        </div>
        <div class="auth-btn">
            <button class="btn admin-btn modal-btn-height w-100" type="submit" [disabled]='changePasswordForm.invalid'>حفظ التغييرات</button>
        </div>
      </form>
    </div>
  </div>
</div> -->

<!-- <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="passwordCreated">
  <div class="login-page">
    <div class="auth-wrapper">
      <div class="row auth-row">
        <div class="col-md-6 authbanner">
          <img class="auth-pic" src="../../../../assets/images/kindi-logo-black.png" alt="صورة التوثيق" width="150" height="60"/>
        </div>
        <div class="col-md-6 form-wrap">
          <form class="text-center">
            <div class="email-sent text-center">
              <img src="../../../../assets/icons/email-sent-icon.svg" alt="البريد الإلكتروني أُرسل" />
            </div>
            <div class="sign-in-heading">
              <h3 class="">تم إنشاء كلمة المرور بنجاح</h3>
              <p class="email-short-instruction">لقد قمت بإنشاء كلمة المرور الجديدة بنجاح. يُرجى استخدام كلمة المرور الجديدة عند تسجيل الدخول.</p>
            </div>
           <div class="auth-btn">
                <button class="btn primary w-100" type="submit"><a [href]="['/admin/login']">العودة لتسجيل الدخول</a></button>
            </div> 
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->



