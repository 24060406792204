<app-navbar></app-navbar>
<div *ngIf="partnercontent" lang="ar">
<div class="partner container mt-20">
  <h3 >اصبح شريكاَ</h3>
  <p>هل أنت مهتم بالتعاون مع دونا؟ نحن حريصون على بدء حوار واستكشاف التعاون المحتمل</p>
  <!-- <h6>please fill in your details below:</h6> -->
  <form  [formGroup]="Partnerform" (submit)="Partnerform.valid && Partnerforms()">
    <!-- <h6>Choose the business type that best mirrors the core operations of your company. <span class="title">*</span></h6> -->
    <div class="input-group mb-20">
      <hr>
      <input dir="ltr" class="floating-input" placeholder="ادخل رسالتك هنا" type="text" formControlName="message" required>
      <h6>من فضلك أخبرنا بأي معلومات إضافية تعتقد أنها مفيدة وسوف تساعدنا</h6>
      <hr>
      <input dir="ltr" class="floating-input" placeholder="ادخل رابط الموقع الخاص بك" type="text" formControlName="website" required>
      <h6>رابط الموقع الخاص بك </h6>
      <hr>
      <input dir="ltr" class="floating-input" placeholder="ادخل اسم الشركة" type="text" formControlName="Company_name" required>
      <h6> اسم الشركة<span class="title">*</span></h6>
      <hr>
      <input dir="ltr" class="floating-input" placeholder="ادخل بريدك الالكتروني" type="email" formControlName="email" required>
      <div class="feedback"  *ngIf="Partnerform.controls['email'].dirty && Partnerform.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
      <h6>بريدك الالكتروني <span class="title">*</span> </h6>
      <hr>
      <input dir="ltr" class="floating-input" placeholder="ادخل اسمك الاخير" type="text" formControlName="last_name" required>
      <h6>الاسم الاخير  <span class="title">*</span></h6>
      <hr>
      <input dir="ltr" class="floating-input" placeholder="ادخل اسمك الاول " type="text" formControlName="first_name" required>
      <h6>الاسم الاول <span class="title">*</span></h6>
      <hr>
    </div>
    <button type="submit" class="btn primary pl-25 pr-25 btn-sub mb-20">ا ر سـا ل</button>
  </form>
</div>
  <!-- <h5>Dona Partners needs the contact information you provide to us to contact you about our products and services. if you have any questions, please contact us</h5> -->
</div>
<div *ngIf="isSubmissionSuccessful" class="container mt-20 isSubmissionSuccessful"> 
  <h6>شكرا لاهتمامك بالعمل معنا! سوف نقوم بالرد عليك في أقرب وقت ممكن. رضاكم هو أولويتنا، ونحن نقدر صبركم. </h6>
</div>
<app-footer></app-footer>

<!-- <app-chat-widght></app-chat-widght> -->

<app-footer></app-footer>



<!-- اختر عطرك, اختيار عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->
<!-- مساعدة في اختيار عطر, عطر على ذوقي, رشح لي عطر, كيف أختار عطر, اختر عطرك, اختيار  عطور, انسب عطر, افضل عطر, عطور نيش, تقنية عطور, رشح عطر, ترشيح عطور, عطر مرشح, نظام ترشيح عطور, اختبار عطور, عطرك المفضل, عطر, عطور, الذكاء الاصطناعي للعطور, عطور الاعلى مبيعًا, متجر عطور -->