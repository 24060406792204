import { ViewportScroller } from "@angular/common";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DEFAULT_PARAMS } from 'src/app/enum/constraints';
import { Component, Input, OnInit,ChangeDetectorRef, ViewChild, ElementRef, HostListener, EventEmitter, Output, } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import { AuthService } from '../../../core/auth.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Router } from '@angular/router';
import { LoginComponent } from "../login/login.component";
import { CommonService } from 'src/app/shared/services/common.service';
import { catchError, forkJoin, map, of } from "rxjs";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rec-page',
  templateUrl: './rec-page.component.html',
  styleUrls: ['./rec-page.component.scss']
})
export class RecPageComponent implements OnInit {
  showNUllscreen;
  viewmore = true;
  isLoading = true;
  page = 3;
  @Input() totalCount: number;
  showWishlist: boolean = false;
  mainNoteArray: string[];
  mainNoteArrayID: string[];
  imageUrls = [];
  subNoteArray: string[];
  datanotes: any;
  mostFrequentSubNotes: string[];
  mostFrequentMainNotes: string[];
  mainNoteDetails: any[];
  subNoteDetails: any[];
  animationFrameId: number;
  toggleWishlist() {
    this.showWishlist = !this.showWishlist;
  }
  @Output() productAdded = new EventEmitter<void>();
  @Input() pageSize: number = 10;
  priceData: any;
  scrollThreshold: number = 100; 
  infoPerfume= true;
  idprodact = [];
  showRec= true;
  selectedProducts: any[];
  isLoggedIn: boolean;
  isLoggedin = true;
  params = DEFAULT_PARAMS;
  loaderRecommdation;
  super_admin = localStorage.getItem("is_super_admin");
  userid = localStorage.getItem("user_id");
  showProductonFirstSCreen = [];
  addPerfume = [];
  showWishlistContent: boolean = false;
  recommendationsCardDataStore : any;
  recommendationsCardData;
  recommendationAdd = [];
  showViewMoreButton = false;
  viewless= false;
  showfav= false;
  fav: any;
  constructor(
    public service: DashboardServiceService,
    public modalRef: BsModalRef,
    private readonly AuthService: AuthService,
    private ref: ChangeDetectorRef,
    public modalService: BsModalService,
    private readonly toastService: ToastService,
    private scroller: ViewportScroller,
    private router: Router,
    public commonService: CommonService,
    private readonly authHttpService: AuthHttpServiceService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly DashboardServiceService: DashboardServiceService,
    private elementRef: ElementRef,
    private titleService: Title
    ) {
      this.isLoggedIn = AuthService.isUserLoggedIn();
      const navigation = this.router.getCurrentNavigation();
      if (navigation && navigation.extras.state) {
        this.addPerfume = navigation.extras.state.data;
      }
    }

  ngOnInit(): void {
    this.titleService.setTitle('التوصيات - Dona AI');
    if (this.addPerfume && this.addPerfume.length > 0) {
        localStorage.setItem('showProductonFirstSCreen', JSON.stringify(this.addPerfume));
    }
      this.checkForShowProductChange();
      
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    this.showViewMoreButton = (window.scrollY > this.scrollThreshold);
  }
  addFavorites(data) {
    const prodact_id = data['product._id'];
    this.showWishlistContent = !this.showWishlistContent;
    if (this.isLoggedIn) {
    if(this.userid==null){
    }else{
      const data = {
        "product_id": prodact_id,
      };
    this.service.addToFavorites(this.userid, data).subscribe(
      response => {
        this.productAdded.emit();
        localStorage.removeItem('priceID');
      },
      error => {
        if (error.error.detail === 'Product already exists in favorites') {
          console.error('Product already exists in favorites');
        } else {
          console.error('Failed to add to favorites:', error);
        }
      }
    )
  }
  }
  }  
  closesection(){
    this.showfav = !this.showfav;
  }
  opensection(){
    this.showfav = true;
  }


    removeProductFromFavorites(productId: string): void {
      const productIndex = this.selectedProducts.findIndex(product => product.id === productId);
  
      if (productIndex !== -1) {
        this.selectedProducts.splice(productIndex, 1);
        localStorage.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
        this.ref.detectChanges();
      }
    }
    
  recommendation() {
    this.loaderRecommdation = true;
    this.recommendationsCardData = [];

    this.recommendationAdd = this.getSelectedProduct();
    if (this.recommendationsCardData) {

    this.authHttpService.recommendation(this.removeDuplicates(this.recommendationAdd).toString())
      .subscribe((response) => {
        // debugger;
        this.loaderRecommdation = false;
        this.recommendationsCardData = response[0]?.top_picks;
        // this.displayedData()
        

        if (this.recommendationsCardData?.length > 0) {
          this.showNUllscreen = false;
        } else {
          this.router.navigateByUrl(`/ar`);
          this.showNUllscreen = true;
        }

        localStorage.setItem('recommendationsCardDataStore', JSON.stringify(response[0]?.top_picks));
        this.UserActivation();
        this.priceRec();
        this.NotesRec();

      });
      
    }else{
      this.checkForShowProductChange();
    }
  }


  getSelectedProduct(pk = false) {
    let selectedProd = []
    if (this.addPerfume) {
      for (let i = 0; i < this.addPerfume?.length; i++) {
        if (!pk) {
          selectedProd.push(this.addPerfume[i]?._id)
        } else {
          selectedProd.push(this.addPerfume[i]?.pk)
        }
      }
    }
    return selectedProd;
  }

  nextPage() {
    this.showViewMoreButton = false;
    this.viewless = true;
    this.viewmore = false; // Ensure viewmore is false when nextPage is clicked
    this.elementRef.nativeElement.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
  }
  
  viewlee() {
    this.viewmore = !this.viewmore;
    this.viewless = !this.viewless;
  }
  
  checkForShowProductChange() {
    const showProductChange = JSON.parse(localStorage.getItem("showProductonFirstSCreen"));
    if (showProductChange !== this.showProductonFirstSCreen) {
        // If showProductonFirstSCreen has changed, update the value and call addPerfumerec
        this.addPerfumerec(showProductChange);
    }
}

addPerfumerec(addPerfume){
    localStorage.setItem('showProductonFirstSCreen', JSON.stringify(addPerfume));
    this.showProductonFirstSCreen = addPerfume;
    this.recommendation();
}

  

  removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  UserActivation() {
    const storedData = localStorage.getItem('recommendationsCardDataStore');
    const recommendationsCardDataStore = storedData ? JSON.parse(storedData) : null;

    const recommendations = recommendationsCardDataStore?.map(product => product["product._id"]) || [];
    const select_products = this.showProductonFirstSCreen?.map(product => product._id) || [];
     if (this.isLoggedIn) {
      const data = {
        type_user: "User",
        user_id: this.userid,
        select_products: select_products,
        recommendation_products: recommendations
      };
      if(this.super_admin === "true"){

      }else{

        this.service.actionuser(data).subscribe(
          (response) => {
            const jsonString = typeof response === 'object' ? JSON.stringify(response) : response;
            this.commonService.setLocalStorage(jsonString);
          },
          (error) => {
            console.error(error);
          }
        );
      }

      }else if(!this.isLoggedIn){
        const data = {
          type_user: "Guste",
          select_products: select_products,
          recommendation_products: recommendations
        };
          this.service.actionuser(data).subscribe(
            (response) => {
              const jsonString = typeof response === 'object' ? JSON.stringify(response) : response;
              this.commonService.setLocalStorage(jsonString);
            },
            (error) => {
              console.error(error);
            }
          );
      }
    
  }


  priceRec() {
    const storedData = localStorage.getItem('recommendationsCardDataStore');
    const recommendationsCardDataStore = storedData ? JSON.parse(storedData) : null;
  
    if (!recommendationsCardDataStore) {
      console.error('No data found in localStorage.');
      return;
    }
  
    const recommendations = recommendationsCardDataStore?.map(product => product["product._id"]) || [];
    const results: { productId: string, priceData: any[] }[] = [];
  
    const priceRequests = recommendations.map(productId => {
      return new Promise<void>((resolve) => {
        this.service.pricesRec(productId, this.params.page_num, this.pageSize).subscribe(
          (data: any[]) => {
            const productDetailsRec: { price_data: any[] } = data as unknown as { price_data: any[] };
            const priceData = productDetailsRec.price_data;
            priceData.sort((a, b) => a.price - b.price);
            const lowestThreePrices = priceData.slice(0, 3);
            results.push({ productId, priceData: lowestThreePrices });
            resolve();
          },
          (error: any) => {
            console.error(`Error in price data for product ID ${productId}:`, error);
            resolve();
          }
        );
      });
    });
  
    Promise.all(priceRequests).then(() => {
      recommendationsCardDataStore.forEach(product => {
        const productId = product["product._id"];
        const result = results.find(item => item.productId === productId);
  
        if (result) {
          product.priceData = result.priceData;
        }
      });
  
      // Update recommendationsCardDataStore in localStorage
      localStorage.setItem('recommendationsCardDataStore', JSON.stringify(recommendationsCardDataStore));
  
      // Optionally, update this.recommendationsCardData if needed
      this.recommendationsCardData = recommendationsCardDataStore;
    });
  }
  

  NotesRec(): void {
    // Collect recommendations
    const recommendations = this.recommendationsCardData.map(product => product["product._id"]);
  
    // Function to fetch note details
    const fetchNoteDetails = (productId: string) => {
      return this.service.dataperfume(productId).pipe(
        map((data: any) => {
          const { mainNote, notes, subNote } = data;
          return { mainNote, notes, subNote };
        }),
        catchError(error => {
          console.error(`Error fetching notes for product ID ${productId}:`, error);
          return of({ mainNote: null, notes: [], subNote: null });
        })
      );
    };
  
    // Get note details for all recommendations
    forkJoin(recommendations.map(productId => fetchNoteDetails(productId))).subscribe(
      (results: { mainNote: any, notes: any[], subNote: any }[]) => {
        // Initialize arrays to collect IDs and names
        const mainNoteArray: string[] = [];
        const mainNoteArrayID: string[] = [];
        const subNoteArray: string[] = [];
        const noteItemIDs: string[] = [];
  
        // Collect IDs and names from mainNote, subNote, and notes
        results.forEach((data) => {
          const { mainNote, subNote, notes } = data;
  
          if (mainNote && mainNote._id && !mainNoteArrayID.includes(mainNote._id)) {
            mainNoteArrayID.push(mainNote._id);
            mainNoteArray.push(mainNote.name_ar); // Collect main note names
          }
  
          if (subNote && subNote._id && !subNoteArray.includes(subNote.name)) {
            subNoteArray.push(subNote.name); // Collect sub note names
          }
  
          if (Array.isArray(notes)) {
            notes.forEach((noteItem) => {
              if (noteItem._id && !noteItemIDs.includes(noteItem._id)) {
                noteItemIDs.push(noteItem._id); // Collect note item IDs
              }
            });
          }
        });
  
        // Function to fetch ingredient details in batches
        const fetchIngredientsInBatches = async (ids: string[]) => {
          const batchSize = 10; // Adjust the batch size as needed
          const results = [];
          for (let i = 0; i < ids.length; i += batchSize) {
            const batchIds = ids.slice(i, i + batchSize);
            const batchResults = await this.service.ingredientDetails(batchIds.join(',')).toPromise();
            results.push(...batchResults);
          }
          return results;
        };
  
        // Fetch ingredient details for mainNote and subNote IDs
        Promise.all([
          fetchIngredientsInBatches(mainNoteArrayID),
          fetchIngredientsInBatches(noteItemIDs) // assuming noteItemIDs are also fetched
        ]).then(([mainNoteDetails, noteItemDetails]) => {
          // Process and store the results
          this.mainNoteDetails = mainNoteDetails;
          this.subNoteDetails = noteItemDetails; // Assuming noteItemDetails include subNoteDetails
  
          // Count occurrences of mainNote names
          const mainNoteCounts = this.countOccurrences(this.mainNoteDetails.map(detail => detail.name_ar));
          this.mostFrequentMainNotes = this.getMostFrequentItems(mainNoteCounts, 2);
  
          // Count occurrences of subNote names
          const subNoteCounts = this.countOccurrences(this.subNoteDetails.map(detail => detail.name_ar));
          this.mostFrequentSubNotes = this.getMostFrequentItems(subNoteCounts, 2);
  
          // Update imageUrls directly from mainNoteDetails
          this.imageUrls = this.mainNoteDetails.map(detail => ({
            image_url: detail.image_url,
            name: detail.name,
            name_ar: detail.name_ar
          }));

          this.isLoading = false;
  
          // Optionally update recommendationsCardData with new details
          this.recommendationsCardData = this.recommendationsCardData;
        }).catch(error => {
          this.isLoading = false;
          console.error('Error fetching ingredient details:', error);
        });
      },
      error => {
        console.error('Error in fetching note details:', error);
      }
    );
  }
  
  // Helper function to count occurrences
  countOccurrences(array: string[]): { [key: string]: number } {
    return array.reduce((acc, item) => {
      acc[item] = (acc[item] || 0) + 1;
      return acc;
    }, {});
  }
  
  // Helper function to get most frequent items
  getMostFrequentItems(counts: { [key: string]: number }, limit: number): string[] {
    return Object.entries(counts)
      .sort(([, countA], [, countB]) => countB - countA)
      .slice(0, limit)
      .map(([item]) => item);
  }
  
  


  extractNumber(priceString) {
    return priceString;
  }


  otherpricesepage(data){
    localStorage.setItem('perfumeData', JSON.stringify(data));
    sessionStorage.setItem('perfumeData', JSON.stringify(data));
    this.router.navigateByUrl(`/ar/product-prices?id=${data}`);
  }


  Perfumeinfo(data,id) {
    localStorage.setItem('priceID',id)
    const cid = data['product._id']
     if (this.idprodact.length < 1) {
        this.idprodact.push(data);
   }else{
    this.idprodact.pop();
    this.idprodact.push(data);
    window.scrollTo(0, 0);
   }
   localStorage.setItem('perfumeData', JSON.stringify(data));
   sessionStorage.setItem('perfumeData', JSON.stringify(data));
  this.router.navigateByUrl(`/ar/product-page?id=${id}&cid=${cid}`);

  }

  openModal(data) {
      window.open(data, '_blank');
      if(!this.isLoggedIn){
    this.modalRef = this.modalService.show(LoginComponent, {
      class: 'Login-modal'
    });
  }
}

userprofile(){
  if (this.isLoggedIn) {
    this.router.navigateByUrl(`/ar/user-profile`);
  }else{
    this.modalRef = this.modalService.show(LoginComponent, {
      class: 'login-modal'
    });

  }
}


}