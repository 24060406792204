import { Component, OnInit } from '@angular/core';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactComponent } from '../contact/contact.component';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { AuthService } from '../../../core/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subSection = true;
  isNotaSubActive: boolean;
  isLoggedIn: boolean;
  modalRef: BsModalRef;
  userid = localStorage.getItem("user_id");
  validationMessage = VALIDATION_MESSAGE;
  Createsubform: UntypedFormGroup;
  constructor(
    public readonly service:DashboardServiceService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly AuthService: AuthService,
    public modalService: BsModalService
    ) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }

  formInitialization() {
    this.Createsubform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  ngOnInit(): void {
    this.formInitialization();
    const isHidden = localStorage.getItem('isNotaSubHidden');
    this.isNotaSubActive = isHidden !== 'true';
    if (!this.isNotaSubActive) {
      this.subSection = false;
    }
  }

  closesection() {
    this.subSection = !this.subSection;
    if (!this.subSection) {
      this.isNotaSubActive = false;
      localStorage.setItem('isNotaSubHidden', 'true');
    } else {
      localStorage.setItem('isNotaSubHidden', 'false');
    }
  }

  Createchatforms() {
    if (this.isLoggedIn) {
    let data = {
      user_id: this.userid,
      email: this.Createsubform.value.email,
    };

    this.service.subscribe(data)
    .subscribe((response) => {
      this.subSection = !this.subSection;
    },(error) => {
    })
  }else if(!this.isLoggedIn){
    let data = {
      email: this.Createsubform.value.email,
    };

    this.service.subscribe(data)
    .subscribe((response) => {
      this.subSection = !this.subSection;
    },(error) => {
    })
  }
}

openModal() {
  this.modalRef = this.modalService.show(ContactComponent, {
    // initialState: {
    //   data: id
    // },
    class: 'perfume-edit-modal'
  });
}

}
