import {ChangeDetectorRef, Component, OnInit,} from '@angular/core';
import {Router} from '@angular/router';
import {VALIDATION_MESSAGE} from 'src/app/enum/constraints';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {REGEX} from 'src/app/enum/validation';
import {ToastService} from 'src/app/shared/services/toast.service';
import {CommonService} from 'src/app/shared/services/common.service';
import {environment} from 'src/environments/environment';
import {AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import {AuthService} from '../../../core/auth.service';
import {DashboardServiceService} from '../../authenticate/dashboard-service.service';
import {DeleteAccountComponent} from '../delete-account/delete-account.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  showfav = false;
  existsEmail = true;
  firstlogin = true;
  selectedProducts: any[] = []
  data;
  id;
  validationMessage = VALIDATION_MESSAGE;
  password1;
  show = false;
  forgetPasswordForm: UntypedFormGroup;
  userID = localStorage.getItem("user_id");
  access_token = localStorage.getItem("access_token");
  user_email = localStorage.getItem("email");
  user_phone = localStorage.getItem("user_phone");
  user_name = localStorage.getItem("user_full_name");
  modalRef: BsModalRef;
  menuOption: boolean;
  langOption: boolean;
  userOption: boolean;
  profileOption: boolean;
  addNewUserForm: UntypedFormGroup;
  showWishlistContent: boolean = false;
  localnotaEmail;
  edit = false;
  forgetpass = false;
  error = false;
  login = false;
  signINSuccessfully: any = true;
  menuCrossIcon = false;
  isUserLoggedIn = false;
  perfumeAdvertiser: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  forgettpassForm: UntypedFormGroup;
  editForm: UntypedFormGroup;
  CreateuserFrm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  loginModal: boolean = false;
  createAcc= false;
  isLoggedIn: boolean;
  password3: string;
  show2: boolean;
  password2: string;
  show1: boolean;
  passmsg: string;
  changePassword = false;
  passwordCreated = false;
  formInitialization() {
    this.CreateuserFrm = this.formBuilder.group({
        full_name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
        phone: ['', [Validators.required]],
        password: ['', [Validators.required]],
      }
    );
    this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
        password: ['', Validators.required],
        rememberMe: false
      }
    );
    this.editForm = this.formBuilder.group({
        full_name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
        phone: ['', [Validators.required]],
      }
    );
    this.forgettpassForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]],
      }
    );
    this.changePasswordForm = this.formBuilder.group({
        code: ['', [Validators.required]],
        new_password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]]
      }, {validator: this.passwordMatchValidator} as AbstractControlOptions
    );
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX.EMAIL)]]
    });
  }

  onClick() {
    if (this.password1 === 'password') {
      this.password1 = 'text';
      this.show = true;
    } else {
      this.password1 = 'password';
      this.show = false;
    }
  }
  constructor(
  private readonly formBuilder: UntypedFormBuilder,
  private ref: ChangeDetectorRef,
  public readonly service: DashboardServiceService,
  public modalService: BsModalService,
  private readonly router: Router,
  public readonly authService: AuthHttpServiceService,
  public commonService: CommonService,
  private readonly toastService: ToastService,
  private readonly AuthService: AuthService
  ) {
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }

  ngOnInit(): void {
    this.formInitialization();
    if (localStorage.getItem("user_id")) {
      this.service.UserData(this.userID).subscribe(
        (data: any) => {
          this.editForm.patchValue({
            full_name: data.full_name,
            email: data.email,
            phone: data.phone
          });
        },
        (error) => {
          // console.error('Error fetching user data:', error);
          this.signINSuccessfully = false;
          this.isUserLoggedIn = false;
          this.router.navigate(['/landing-page/login']);
          this.login = true;
          console.error('Error fetching user data:', error);
        }
      );
    }


  }

  loginautomatically(email: string, password: string) {
    const formData: any = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    formData.append("scope", "dona");

    this.service.loginuser(formData)
      .subscribe((response) => {
          location.reload()
          if (response) {
            localStorage.setItem("email", email);
            localStorage.setItem("user_id", password);
            this.commonService.setLocalStorage(response)
            this.commonService.setLocalStorage(response)
            this.login = !this.login;
            localStorage.setItem("email", email);
            this.localnotaEmail = localStorage.getItem("notaEmail")
            const token = localStorage.getItem("access_token");
            if (token) {
              this.isUserLoggedIn = true;
            }

          }

        },
        (error) => {
          console.error('Login failed', error);
          this.error = true;
          this.firstlogin = false;
        }
      );
  }

  wishlist() {
    this.showWishlistContent = !this.showWishlistContent;
  }

  menuOpen() {
    setTimeout(() => {
      this.menuCrossIcon = !this.menuCrossIcon;
    }, 500);
  }

  delleteaccount(id?): void {
    this.modalRef = this.modalService.show(DeleteAccountComponent, {
        initialState: {
          data: id
        },
      }
    );
    this.edit = !this.edit
  }


  Createuserform() {
    let data = {
      full_name: this.CreateuserFrm.value.full_name,
      email: this.CreateuserFrm.value.email,
      phone: this.CreateuserFrm.value.phone,
      password: this.CreateuserFrm.value.password,
    }
    this.service.creatuser(data)
      .subscribe((response) => {
        this.commonService.setLocalStorage(response);
        this.login = !this.login;
        const email = this.CreateuserFrm.value.email;
        const password = this.CreateuserFrm.value.password;
        this.loginautomatically(email, password);
      }, (error) => {
        const errorMessage = error.toString();
        if (errorMessage.includes('The user with this email already exists in the system.')) {

          this.existsEmail = true;
          this.existsEmail = !this.existsEmail;
        }
      });
  }

  loginform() {
    const formData: any = new FormData();
    formData.append("username", this.loginForm.value.email);
    formData.append("password", this.loginForm.value.password);
    formData.append("scope", "dona")
    this.service.loginuser(formData)
      .subscribe((response) => {
          location.reload()
          if (response) {
            localStorage.setItem("email", this.loginForm.value.email);
            localStorage.setItem("user_id", response.user_id);
            this.commonService.setLocalStorage(response)
            this.commonService.setLocalStorage(response)
            this.login = !this.login;
            localStorage.setItem("email", this.loginForm.value.email);
            this.localnotaEmail = localStorage.getItem("notaEmail")
            const token = localStorage.getItem("access_token");
            if (token) {
              this.isUserLoggedIn = true;
            } else {

            }

          }

        },
        (error) => {
          console.error('Login failed', error);
          this.error = true;
          this.firstlogin = false;
        }
      );
  }

  closesection() {
    this.showfav = !this.showfav;
  }

  opensection() {
    this.showfav = true;
  }


  updateUserData() {
    this.service.updateUserData(this.userID, this.editForm.value)
      .subscribe(
        (response) => {
          this.editForm.reset();
          this.edit = !this.edit;
        },
        (error) => console.error('فشل في تحديث البيانات:', error)
      );
  }


  removeProductFromFavorites(productId: string): void {
    const productIndex = this.selectedProducts.findIndex(product => product.id === productId);

    if (productIndex !== -1) {
      this.selectedProducts.splice(productIndex, 1);
      localStorage.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
      this.ref.detectChanges();
    }
  }

  editProfile() {
    this.edit = !this.edit;
  }

  signout() {
    localStorage.clear();
    this.signINSuccessfully = false;
    this.isUserLoggedIn = false;
    this.router.navigateByUrl('/ar');
    location.reload();
  }

  menuAction() {
    this.menuOption = !this.menuOption;
    this.langOption = false;
  }

  usermenu() {
    this.profileOption = !this.profileOption;
  }

  SelectedLanguage() {
    this.menuOption = false;
    this.langOption = !this.langOption;
  }

  toggleConversationalView() {
    this.login = !this.login;
    this.userOption = !this.userOption;
    if (this.isUserLoggedIn) {
      this.profileOption = !this.profileOption;
    }
    this.ref.detectChanges();

  }


  close() {
    this.login = !this.login;
  }

  closeform() {
    this.edit = !this.edit;
  }

  loginforms() {
    this.login = false;
  }

  showlogin() {
    this.login = true;
    this.createAcc = false; 
  }

  createAccount(){
    this.createAcc = true;
  }

  closeAccount(){
    this.createAcc = false;
    this.login = !this.login
  }

  Forgotten(){
    this.forgetpass = true;
    this.login = false;
  }


  closepass() {
    this.forgetpass = false;
  }

  changePass() {
    this.service.resetPassword(this.changePasswordForm.value)

      .subscribe((response) => {
        if (response) {
          this.toastService.showSuccess(VALIDATION_MESSAGE.CHANGE_PASSWORD);
          this.passwordCreated = true;
        }

      });
  }

  checkPassSame() {
    let pass = this.changePasswordForm.value.new_password;
    let passConf = this.changePasswordForm.value.confirm_password;
    if (pass == passConf && this.changePasswordForm.valid === true) {
      this.passmsg = "";
      return false;
    } else {
      this.passmsg = this.validationMessage.CONFIRM_PASSWORD;
      return true;
    }
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    return frm.controls['new_password'].value ===
    frm.controls['confirm_password'].value ? null : {'mismatch': true};
  }

  Click(pass) {
    if (pass === 'password1') {
      if (this.password1 === 'password') {
        this.password1 = 'text';
        this.show = true;
      } else {
        this.password1 = 'password';
        this.show = false;
      }
    } else if (pass === 'password2') {
      if (this.password2 === 'password') {
        this.password2 = 'text';
        this.show1 = true;
      } else {
        this.password2 = 'password';
        this.show1 = false;
      }
    } else {
      if (this.password3 === 'password') {
        this.password3 = 'text';
        this.show2 = true;
      } else {
        this.password3 = 'password';
        this.show2 = false;
      }
    }
  }

  forgetPassword() {
    this.service.forgetPassword(this.forgetPasswordForm.value)
      .subscribe((response) => {
        if (response)
          this.toastService.showSuccess(response.message);
      });
  }


}
