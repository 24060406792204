<app-navbar></app-navbar>
<div class="product-page">
  <aside class="sidebar">
    <h6>Recommendations filter </h6>
      <!-- <p (click)="clearClick()">Rest</p> -->
    <ul >
      <li>
        <div class="filter-options" (click)="handleClick('Discount')">
          <img class="click" alt="icon" [src]="isClicked === 'Discount' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>Discount</p>
        </div>
      </li>
      <li>
        <div class="filter-options" (click)="handleClick('LowPrices')">
          <img class="click" alt="icon" [src]="isClicked === 'LowPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>Price (low to high)</p>
        </div>
      </li>
      <li>
        <div class="filter-options" (click)="handleClick('HighPrices')">
          <img class="click" alt="icon" [src]="isClicked === 'HighPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>Price (high to low)</p>
        </div>
      </li>
      <li>
      </li>
            
                  </ul>
  </aside>
<section class="info-perfume">
        <div class="container  feature-container padme-perfume">   
          <div class="filter-btn">
              <h2 class="mobile-filter">Explore Prices for {{this.nameperfume}} Across Different Stores</h2>
          </div>
          <hr>
          <div dir="ltr" class="top-recommendations row">
            <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of priceDatanew;let i = index">
            <div class="" >
              <div class="perfume-pic perfume-pic-store">
                <img class="perfume-img perfume-img-store"  
                  src="{{image}}"  alt="Perfume2" />
              </div>
              <div class="perfume-des">
                <div class="nota-info">
                  <div class="nota-info-2">
                    <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                      <h6 class="perfume-title"  >{{data["name"]}}</h6>
                    </div>
                    <div class="nota-nota-tooltip-hover">
                      <p class="perfume-subtitle">{{data["brand"]}}</p>
                    </div>
                
                </div>
                <div class="nota-nota-tooltip-hover div-prices">
                  <p class="perfume-subtitle price">
                      <ng-container *ngIf="data['sale'] && data['sale'] !== data['price']">
                          <span class="original-sale">{{ extractNumber(data['price']) | number: '1.2-2' }} SAR</span>
                          <span class="original-price">{{ extractNumber(data['sale']) | number: '1.2-2' }} SAR</span>
                      </ng-container>
                      <ng-container *ngIf="!data['sale'] || data['sale'] === data['price']">
                          {{ extractNumber(data['price']) | number: '1.2-2' }} SAR
                      </ng-container>
                  </p>
              </div>
              
                </div>
                <div class="nota-info">
                  <div class="nota-nota-tooltip-hover store">
                    <a><img  
                        src="{{data['logo']}}"  alt="Perfume2" /></a>
                    </div>
                    <button  class="add-to-cart btn primary pl-25 pr-25" (click)="Perfumeinfo(data,data['link'])"> <a  target="_blank" href="{{data.link}}"> Get it now</a></button>
                    <!-- <button class="add-to-cart btn primary pl-25 pr-25" (click)="clickuser($event, data['link'])">
                      <a href="{{data['link']}}" target="_blank">Get it now</a>
                  </button> -->

                </div>
          
              <div *ngIf="priceDatanew && priceDatanew.length === 0">
                <p>Currently, there are no price discount for this perfume.</p>
              </div>
              </div>
            </div>
              
            </div>
          </div>  
              

</div>
</section>
</div>
<app-footer></app-footer>
  
        
          
      

  <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->