import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private readonly toastr: ToastrService
    ) { }
  /**
 * @description show success toaster
 * @param msg toast message
 */
  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  /**
   * @description show error toaster
   * @param msg toast message
   */
  showError(msg: string) {
    this.clear();
    this.toastr.error(msg);
  }

  /**
   * @description show warning toaster
   * @param msg toast message
   */
  showWarning(msg: string) {
    this.toastr.warning(msg);
  }

  /**
   * @description clear toaster
   * @param msg toast message
   */
  clear(toastId?: number) {
    this.toastr.clear(toastId);
  }
}
