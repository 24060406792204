<div class="center">
    <div class="box">
      <div  [ngClass]="{'active': isNotaSubActive}" *ngIf="subSection">
    <p class="mt-20">Stay informed! Sign up for our newsletter and get the latest prices, and exclusives delivered to your email.</p>
    <form [formGroup]="Createsubform" (submit)="Createsubform.valid && Createchatforms()" class="dona-sub" >
        <div class="input-group mb-20 mt-40">
          <input dir="ltr" class="floating-input" placeholder="Enter your email address" type="email" formControlName="email" required>
          <div class="feedback" *ngIf="Createsubform.controls['email'].dirty && Createsubform.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
          <div class="feedback" *ngIf="Createsubform.controls['email'].dirty && Createsubform.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
          <hr class="line-input">
        </div>
        <div class="text-align-right">
          <button type="submit" class="btn primary pl-25 pr-25 btn-sub">Subscribe</button>
          <!-- <p class="mt-20">By clicking subscribe you agree to our <a  [href]="['/terms']">terms</a> and <a [href]="['/privacy']">privacy</a> policy.</p> -->
        </div>
    </form>
  </div>
  <div class="d-flex footer">
    <p class="mt-20">© Dona 2023 - 2024 </p>
    <p class="mt-20 ml-20" (click)="openModal()">Contact us  </p>
  </div>
  </div>
</div>

