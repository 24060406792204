<div class="login-modal-nota-nota after-login-modal-transparent"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()" src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h3> حذف حسابك على دونا</h3>
      <hr>
      <h6>هل انت متاكد من انك تريد حذف حسابك على دونا?</h6>
      <p>بمجرد حذف حسابك لن تتمكن من الوصول الى دونا مرة اخرى.</p>      
      <div class="advisor-slide-nota-nota">
          <div class="text-align-right">
            <button (click)="deleteUserAccount()" class="btn primary pl-25 pr-25 btn-login">حذف حسابي</button>
          </div>
      </div>
    </div>
  </div>
</div>
