<div class="login-modal-nota-nota after-login-modal-transparent"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()" src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h3> Delete your account on Dona</h3>
      <hr>
      <h6>Are you sure you want to delete your account on Dona?</h6>
      <p>Once you delete your account, you will no longer have access to your account on Dona.</p>      
      <div class="advisor-slide-nota-nota">
          <div class="text-align-right">
            <button  (click)="deleteUserAccount()" class="btn primary pl-25 pr-25 btn-login">Delete my account</button>
          </div>
      </div>
    </div>
  </div>
</div>
