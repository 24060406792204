<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="!login"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h2>Login to your account</h2>
     <h6 *ngIf="firstlogin">Wellcom back to your account</h6>
     <h6 *ngIf="error" class="error">Incorrect Email Address or Password</h6>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="loginForm" (submit)="loginForm.valid && loginform()">
            <div class="form-floating  {{(loginForm.controls['email'].dirty && loginForm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">Email Address</label>
                <div class="feedback"  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>

            </div>
            <div class="form-floating {{(loginForm.controls['password'].dirty && loginForm.controls['password'].invalid)?'invalid-form-group':''}}">
              <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password">
              <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
              <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
              <label for="floatingPassword">Password</label>
              <div class="feedback"
              *ngIf="loginForm.controls['password'].dirty &&
              loginForm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED}}</div>
          </div>
          <div class="text-align-right">
            <button type="submit" class="btn primary pl-25 pr-25 btn-login" 
             >Log in</button>
             <h6 class="nav-title">you don't have a DONA account? <a (click)="Creatuser()">Sign up</a></h6>
          </div>
        </form>
      </div>
    </div>
  </div>
 </div>



<div  *ngIf="createacc">
  <div class="login-modal-nota-nota after-login-modal-transparent"> 
   <div class="login-page">
     <div class="login-head mb-20">
       <img   (click)="modalRef.hide()"
         src="/assets/icons/close-icon.svg" alt="Close icon" />
     </div>
     <div class="login-body">
      <h2>Let’s create your account</h2>
       <h6>Signing up for DONA is fast and free</h6>
       <div class="advisor-slide-nota-nota">

        <div *ngIf="!existsEmail" class="exists-email">
          <p>This email is already registered <a (click)="loginforms()">Log in</a></p>
        </div>
         <form [formGroup]="CreateuserFrm" (submit)="CreateuserFrm.valid && Createuserform()">  
          <div class="form-floating  {{(CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].invalid)?'invalid-form-group':''}}">
            <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
            <label for="floatingInput">Email Address</label>
              <div class="feedback"  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                 <div class="feedback "  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
          </div>
          <div class="form-floating ">
            <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password">
            <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
            <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
            <label for="floatingPassword">Password</label>
            <div class="feedback"
            *ngIf="CreateuserFrm.controls['password'].dirty &&
            CreateuserFrm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED}}</div>
        </div>
        <div class="form-floating  ">
          <input type="text" class="form-control" pattern="[A-Za-z\s]+" formControlName="full_name" placeholder="your name">
          <label for="floatingInput">Name ( EN )</label>
            <div class="feedback"  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
               <div class="feedback "  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
        </div>
        <div class="form-floating ">
          <input type="tel" pattern="[0-9]{10,15}"  class="form-control" formControlName="phone" placeholder="your phone">
          <label for="floatingInput">Phone number</label>
            <div class="feedback"  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('pattern')">{{validationMessage.INVALID_PHONE}}</div>
               <div class="feedback "  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('required')">{{validationMessage.PHONE_REQUIRED}}</div>
        </div>
          <div class="text-align-right">
            <button type="submit" class="btn primary pl-25 pr-25 btn-login" 
            >Sign up</button>
            <h6 class="nav-title">Already have a DONA account? <a (click)="loginforms()">Log in</a></h6>
          </div>
        </form>
       </div>
     </div>
   </div>
  </div>
</div>


<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="forgetpass"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img (click)="modalRef.hide()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h2>Find your Dona account</h2>
     <h6 *ngIf="firstlogin">Enter the email associated with your account to change your password.</h6>
     <h6 *ngIf="error" class="error">Incorrect Email Address or Password</h6>
  
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="forgettpassForm" (submit)="forgettpassForm.valid && loginform()">
            <div class="form-floating  {{(forgettpassForm.controls['email'].dirty && forgettpassForm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">Email Address</label>
                <div class="feedback"  *ngIf="forgettpassForm.controls['email'].dirty && forgettpassForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="forgettpassForm.controls['email'].dirty && forgettpassForm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>

            </div>
          <div class="text-align-right">
            <button type="submit" class="btn primary pl-25 pr-25 btn-login" 
             >Next</button>
          </div>
        </form>
      </div>
    </div>
  </div>
 </div>