<app-navbar></app-navbar>
              <div class="top-perfume " >
                <div class="header-setion">
                <h3> Your Wishlist</h3>
              </div>
                <hr>
                <div *ngIf="!isLoggedIn" class="container">
                  <p> You need to sign in to mark this as a favorite. </p>
                  </div>
                <div class="perfume-wrap " *ngFor="let data of favorite_products_data;let i = index">
                  <div class="our-recommended-nota-nota-other">
                    <div class="perfume-pic">
                      <img class="perfume-img" src="{{ data.product_details.image_url }}"    onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume" />
                    </div>
                    <div class="perfume-des">
                      <div class="nota-nota-tooltip-hover">
                        <h6 class="perfume-title">{{data.product_details.name}}</h6>
                        <h6 class="perfume-title">{{data.product_details.brand}}</h6>
                      </div>                
                  </div>        
                <div>
                  <button  class="add-to-cart btn primary pl-25 pr-25" (click)="otherpricesepage(data.product_details.id)"> Discover prices  </button>
                  <button  class="add-to-cart btn primary pl-25 pr-25 mt-2" (click)="removefav(data.product_details.id)" > Delete  </button>
                </div>
                </div>
              </div>
              <div *ngIf="!empaty" class="container perfume-wrap">
                <img src="../../../../assets/images/Frame.svg" >
                </div>
            </div>
              <app-footer></app-footer>
