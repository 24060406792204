import {  Component, Input, Output, EventEmitter ,ChangeDetectorRef  } from '@angular/core';
import { AuthService } from '../../../core/auth.service';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { DEFAULT_PARAMS } from 'src/app/enum/constraints';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent  {
  userid = localStorage.getItem("user_id");
  empaty= false;
  showNext = true;
  modalRef: BsModalRef;
  max =5;
  maxPages: number = 0;
  previous =true;
  showPrev = true;
  params = DEFAULT_PARAMS;
  @Input() index: number = 1;
  @Input() pageSize: number=10 ;
  @Input() totalCount: number;
  isLoggedIn: boolean;
  showfav = true;
  @Input() showArrow: boolean;
  @Input() isLoggedin: boolean;
  @Input() selectedProducts: any[];
  total: any;
  @Output() closeSection = new EventEmitter<void>();
  @Output() removeFromFavorites = new EventEmitter<string>();
  @Output() productAdded = new EventEmitter<void>();
  fav: any;
  favorite_products_data: any;
  onProductAdded() {
    this.listfav();
  }
  closesection() {
    this.showfav = false;
  }
  constructor(
    private ref: ChangeDetectorRef,
    private readonly AuthService: AuthService,
    public service: DashboardServiceService,
    private router: Router
    ) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }

  ngOnInit(): void {
    if(this.isLoggedIn){
      this.listfav();
      }
  }
  removefav(productId){
    this.service.RemoveFavorites(this.userid,productId).subscribe(
      response => {
        this.listfav();
      },
      error => {
      }
    
    )
  }

  extractNumber(priceString) {
    return priceString;
  }
  
  listfav() {
    if (this.userid) {
      const user_id = this.userid; 
      const page_num = 1;
      const page_size = 10; 
      this.service.getUserFavorites(user_id).subscribe((res) => {
      this.favorite_products_data = res.favorite_products_data    
      this.total = res.Total_count;
      if(this.total === 0 ){
        this.empaty= true;
      }
      });
    } 
  }

  otherpricesepage(data){
    localStorage.setItem('perfumeData', JSON.stringify(data));
    sessionStorage.setItem('perfumeData', JSON.stringify(data));
    this.router.navigateByUrl(`/ar/product-prices?id=${data}`);
  }
  

}
