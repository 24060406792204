import {Component, OnInit} from '@angular/core';
import {AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DashboardServiceService} from '../../authenticate/dashboard-service.service';
import {VALIDATION_MESSAGE} from 'src/app/enum/constraints';
import {ToastService} from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  changePassword: UntypedFormGroup;
  passmsg: string;
  passwordCreated = false;
  main = true;
  validationMessage = VALIDATION_MESSAGE;
  password1;
  password2;
  password3;
  show = false;
  show1 = false;
  show2 = false;
  errorMessage =  false;
  constructor(
      private readonly formBuilder: UntypedFormBuilder,
      public modalRef: BsModalRef,
      private readonly toastService: ToastService,
      public service: DashboardServiceService
      ) {
  }

  ngOnInit(): void {
    this.formInitialization();
    this.password1 = 'password';
    this.password2 = 'password';
    this.password3 = 'password';
  }

  formInitialization() {
    this.changePassword = this.formBuilder.group({
        code: ['', [Validators.required]],
        new_password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]]
      }, {validator: this.passwordMatchValidator} as AbstractControlOptions
    );
  }

  checkPassSame() {
    let pass = this.changePassword.value.new_password;
    let passConf = this.changePassword.value.confirm_password;
    if (pass == passConf && this.changePassword.valid === true) {
      this.passmsg = "";
      return false;
    } else {
      this.passmsg = this.validationMessage.CONFIRM_PASSWORD;
      return true;
    }
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    return frm.controls['new_password'].value ===
    frm.controls['confirm_password'].value ? null : {'mismatch': true};
  }

  changePasswords() {
    this.service.resetPassword(this.changePassword.value)
      .subscribe((response) => {
        if (response) {
          this.main = false;
          this.passwordCreated = true;
          this.toastService.showSuccess(VALIDATION_MESSAGE.CHANGE_PASSWORD);

        }
      },(error) => {
        const errorMessage = error.toString();
        if (errorMessage.includes('New password must be strong with 11 characters and include special characters')) {
          this.errorMessage = true;
        }
      }
      
      );
  }

  onClick(pass) {
    if (pass === 'password1') {
      if (this.password1 === 'password') {
        this.password1 = 'text';
        this.show = true;
      } else {
        this.password1 = 'password';
        this.show = false;
      }
    } else if (pass === 'password2') {
      if (this.password2 === 'password') {
        this.password2 = 'text';
        this.show1 = true;
      } else {
        this.password2 = 'password';
        this.show1 = false;
      }
    } else {
      if (this.password3 === 'password') {
        this.password3 = 'text';
        this.show2 = true;
      } else {
        this.password3 = 'password';
        this.show2 = false;
      }
    }
  }
}
