<app-navbar></app-navbar>
<div class="auth-wrapper"  *ngIf="main" lang="ar">
    <div class="col-md-4 form-wrap container">
        <form  [formGroup]="changePassword" (submit)="changePassword.valid && changePasswords()" >
            <div class="sign-in-heading">
                <h3 class="">اعادة تعين كلمة المرور</h3>
                <hr>
                <p class="auth-short-des w-100">كلمة المرور الجديدة يجب ان تكون مختلفة عن كلمة المرور المستخدمة سابقا</p>
            </div>  
            <hr> 
            <p *ngIf="errorMessage" class="error">يجب أن يكون كلمة المرور الجديدة قوية بطول 11 حرفًا وأن تتضمن رموزًا خاصة</p>
            <div class="form-floating">
                <input [type]="password2" class="form-control" formControlName="new_password" placeholder="New Password" >
                <a class="show-password"><img *ngIf ="show1" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password" (click)="onClick('password2')"></a>
            <a class="show-password"><img *ngIf ="!show1"src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password" (click)="onClick('password2')"></a>
                <label for="floatingInput">كلمة المرور الجديدة</label>
                <div class="feedback"
                *ngIf="changePassword.controls['new_password'].dirty &&
                changePassword.controls['new_password'].hasError('required')">{{validationMessage.NEW_PASSWORD_REQUIRED}}</div>
            </div>
            <div class="form-floating">
                <input [type]="password3" class="form-control" formControlName="confirm_password" placeholder="Confirm New Password" >
                <a class="show-password"><img *ngIf ="show2" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password" (click)="onClick('password3')"></a>
            <a class="show-password"><img *ngIf ="!show2" src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password" (click)="onClick('password3')"></a>
                <label for="floatingInput">تاكيد كلمة المرور</label>
                <br>
                <div *ngIf="checkPassSame() && !changePassword.controls['confirm_password'].hasError('required')  ">
                    <p class="feedback">{{passmsg}} </p>
                  </div> 
            </div>
            <div class="auth-btn">
                <button class="btn admin-btn w-100" type="submit" [disabled]='changePassword.invalid '>Save Changes</button>
            </div>
        </form>
    </div>
</div>


<div class="auth-wrapper" *ngIf="passwordCreated">
  <div class="col-md-4 form-wrap container">
        <form class="text-center">
            <div class="email-sent text-center">
                <img src="../../../../assets/icons/email-sent-icon.svg" alt="Email sent" />
            </div>
            <div class="sign-in-heading">
                <h3 class="">تم اعادة تعين كلة المرور بنجاح</h3>
                <hr>
                <p class="email-short-instruction">من فضلك قم بادخال كلمة المرور الجديدة في عملية تسجيل الدخول </p>
            </div>
            <div class="auth-btn">
                <button class="btn primary w-100" type="submit"><a [href]="['/ar']">الرجوع الى صفحة تسجيل الدخول</a></button>
            </div>
        </form>
    </div>
</div>

<app-footer></app-footer>

