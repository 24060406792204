import { Component, OnInit } from '@angular/core';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { AuthService } from '../../../core/auth.service';
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  validationMessage = VALIDATION_MESSAGE;
  Partnerform: UntypedFormGroup;
  isLoggedIn: boolean;
  userid = localStorage.getItem("user_id");
  isSubmissionSuccessful: boolean = false;
  partnercontent: boolean = true;
  formInitialization() {
    this.Partnerform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      website: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      Company_name: ['', Validators.required],
    });
  }
  constructor(
    public readonly service:DashboardServiceService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly AuthService: AuthService
    ) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
  }

  ngOnInit(): void {
    this.formInitialization();
  }

  Partnerforms() {
    if (this.isLoggedIn) {
    let data = {
      user_id: this.userid,
      email: this.Partnerform.value.email,
      message: this.Partnerform.value.message,
      website: this.Partnerform.value.website,
      first_name: this.Partnerform.value.first_name,
      last_name: this.Partnerform.value.last_name,
      Company_name: this.Partnerform.value.Company_name,
    };
    this.service.partner(data)
    .subscribe((response) => {
      this.isSubmissionSuccessful = true;
      this.partnercontent = false;
    },(error) => {
    })
  }else if(!this.isLoggedIn){
    let data = {
      email: this.Partnerform.value.email,
      message: this.Partnerform.value.message,
      website: this.Partnerform.value.website,
      first_name: this.Partnerform.value.first_name,
      last_name: this.Partnerform.value.last_name,
      Company_name: this.Partnerform.value.Company_name,
    };
    this.service.partner(data)
    .subscribe((response) => {
      this.isSubmissionSuccessful = true;
      this.partnercontent = false;
    },(error) => {
    })
  }
}

}
