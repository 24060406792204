
  <div  *ngIf="createAcc">
    <div class="login-modal-nota-nota after-login-modal-transparent"> 
     <div class="login-page">
      <img  (click)="closeAccount()"
      src="/assets/icons/close-icon.svg" class="login-head" alt="Close icon" />
      <h2>Signing up For DONA now.</h2>
       <h6>it's easy and fast</h6>
       <hr>    
       <div class="login-body">
         <div class="advisor-slide-nota-nota">
          <div *ngIf="!existsEmail" class="exists-email">
            <p>This email is already registered <a (click)="showlogin()">Log in</a></p>
          </div>
           <form [formGroup]="CreateuserFrm" (submit)="CreateuserFrm.valid && Createuserform()">  
            <label for="floatingInput">Email  (*)</label>
            <div class="form-floating  {{(CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
                <div class="feedback"  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="CreateuserFrm.controls['email'].dirty && CreateuserFrm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
            </div>
            <label for="floatingInput">password (*)</label>
            <div class="form-floating ">
              <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password (required)">
              <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
              <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
              <div class="feedback"
              *ngIf="CreateuserFrm.controls['password'].dirty &&
              CreateuserFrm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED}}</div>
          </div>
          <label for="floatingInput"> Name ( EN *) </label>
          <div class="form-floating  ">
            <input type="text" class="form-control" pattern="[A-Za-z\s]+" formControlName="full_name" placeholder="your name">
              <div class="feedback"  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('pattern')">{{validationMessage.INVALID_NAME}}</div>
                 <div class="feedback "  *ngIf="CreateuserFrm.controls['full_name'].dirty && CreateuserFrm.controls['full_name'].hasError('required')">{{validationMessage.NAME_REQUIRED}}</div>
          </div>
          <label >Phone</label>
          <div class="form-floating">
            <input type="tel" pattern="[0-9]{10,15}" class="form-control" formControlName="phone" placeholder="your phone">
<!--              <div class="feedback"  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('pattern')">{{validationMessage.INVALID_PHONE}}</div>-->
<!--                 <div class="feedback "  *ngIf="CreateuserFrm.controls['phone'].dirty && CreateuserFrm.controls['phone'].hasError('required')">{{validationMessage.PHONE_REQUIRED}}</div>-->
          </div>
              <button type="submit" class="btn primary pl-25 pr-25 btn-login" 
              >Sign up</button>
              <h6 class="nav-title">Already have a DONA account? <a (click)="showlogin()">Log in</a></h6>
          </form>
         </div>
       </div>
     </div>
    </div>
  </div>
    <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="login"> 
      <div class="login-page">
        <div class="login-head mb-20">
          <img (click)="close()"
            src="/assets/icons/close-icon.svg" alt="Close icon" />
        </div>
        <div class="login-body">
          <h2>Login To Your Account Now</h2>
          <hr>  
         <!-- <h6 *ngIf="firstlogin">Wellcom back to your account</h6> -->
         <h6 *ngIf="error" class="error">Incorrect Email Address or Password</h6>
          <div class="advisor-slide-nota-nota">
            <form [formGroup]="loginForm" (submit)="loginForm.valid && loginform()">
              <label for="floatingInput">Email</label>
              <div class="form-floating  {{(loginForm.controls['email'].dirty && loginForm.controls['email'].invalid)?'invalid-form-group':''}}">
                  <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
                    <div class="feedback"  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                       <div class="feedback "  *ngIf="loginForm.controls['email'].dirty && loginForm.controls['email'].hasError('required')">{{validationMessage.EMAIL_REQUIRED}}</div>
                </div>
                <label for="floatingPassword">Password</label>
                <div class="form-floating {{(loginForm.controls['password'].dirty && loginForm.controls['password'].invalid)?'invalid-form-group':''}}">
                  <input [type]="password1" formControlName="password"  class="form-control" placeholder="Password">
                  <a class="show-password" (click)="onClick()" *ngIf="show"><img class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password"></a>
                  <a class="show-password" (click)="onClick()" *ngIf="!show"><img src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password"></a>
                  <div class="feedback"
                  *ngIf="loginForm.controls['password'].dirty &&
                  loginForm.controls['password'].hasError('required')">{{validationMessage.PASSWORD_REQUIRED}}</div>
              </div>
              <h6 class="nav-title">Don't have a DONA account? <a cless="text-style"(click)="createAccount()">Sign up</a></h6>
              <div class="text-align-right">
                <button type="submit" class="btn primary pl-25 pr-25 btn-login"
                 >Log in</button>
                 
                 <p (click)="Forgotten()">Forgot your password?</p>
              </div>
            </form>
          </div>
        </div>
      </div>
     </div>

<div class="both">
  <div class="box-navbar">
    <button class="sign-in" *ngIf="isLoggedIn" ><div class="nav-link" data-target="#login" data-toggle="modal" (click)="signout()">
      logout<img class="ml-10" src="../../../../assets/icons/user.svg" width="auto" height="auto" alt="user">
    </div></button>
    <button data-toggle="modal"  data-target="#login"  class="sign-in" *ngIf="!isLoggedIn" ><div class="nav-link" data-target="#login" data-toggle="modal" (click)="createAccount()">
      Create Account<img class="ml-10" src="../../../../assets/icons/user.svg" width="auto" height="auto" alt="user">
    </div></button>
    <button class="btn arabic-box"><a class="text-with-underline"  [href]="['/ar']"  lang="ar">العربية </a><img class="" src="../../../../assets/icons/arabic.svg"></button>
  </div>
<div>
  <div class="navbar-brand">
    <!-- <img class="menu-mobile" [src]="'../../../../assets/images/menu.svg'"> -->
  <a  href="/">
    <img class="logo mb-2" [src]="'../../../../assets/images/logo-without-bg.png'">
    <!-- <p class="mb-5">Perfect Scents,  Anytime</p> -->
  </a>  
</div >
<div class="menu">
  <div><a *ngIf="isLoggedIn" [href]="['/user-profile']">Your Scent Profile</a>
    <span *ngIf="!isLoggedIn" (click)="showlogin()">Your Scent Profile</span>
  </div>
  <div><a *ngIf="isLoggedIn" [href]="['/wishlist']">Wishlist</a>
    <span *ngIf="!isLoggedIn" (click)="showlogin()">Wishlist</span>
  </div>
  <div *ngIf="isLoggedIn" (click)="editProfile()">Edit info</div>
  <div *ngIf="!isLoggedIn" (click)="showlogin()">Edit info</div>
</div>
</div>


<div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="edit">
  <div class="edit-page">
    <div class="login-head mb-20">
<!--       <img  (click)="closeEditForm()" -->
      <img  (click)="closeEditForm()"
        src="/assets/icons/close-icon.svg" alt="Close icon" />
    </div>
    <div class="login-body">
      <h3 class="login-title">Edit your information </h3>
      <hr>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="editForm" (submit)="editForm.valid && updateUserData()">
          <label for="floatingInput">Name</label>
          <div class="form-floating">
                  <input type="email" class="form-control" placeholder="{{user_name}}" type="text" formControlName="full_name">
                </div>
                <label for="floatingInput">Email</label>
                <div class="form-floating">
                  <input type="email" class="form-control" placeholder="{{user_email}}" type="email" formControlName="email">
                </div>
                
                <div class="form-floating" *ngIf="user_phone">
                  <label for="floatingInput">Phone </label>
                  <input type="tel" pattern="[0-9]{10,15}" class="form-control" placeholder="{{user_phone}}" type="phone" formControlName="phone">
                </div>
                <div class="text-align-right button-box">
            <button type="submit" class="btn save primary pl-25 pr-25 btn-login" 
             >Save </button>
             <button type="submit" (click)="delleteaccount(this.userID)" class="btn delete-button primary pl-25 pr-25 btn-login" 
                   >Delete my account </button>
          </div>
        </form>
      </div>
    </div>
  </div>
 </div>

 <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="forgetpass"> 
  <div class="login-page">
    <div class="login-head mb-20">
      <img  (click)="closepass()"
      src="/assets/icons/close-icon.svg" alt="Close icon" />
  </div>
    <div class="login-body">
     <h6 *ngIf="error" class="error">Incorrect Email Address or Password</h6>
      <div class="advisor-slide-nota-nota">
        <form [formGroup]="forgetPasswordForm" (submit)="forgetPasswordForm.valid &&  forgetPassword()">
          <div class="sign-in-heading">
              <h3 class="">Forgot Password?</h3>
              <p class="auth-short-des">Enter the email address associated with your account and we'll send an email with instructions to reset your password.</p>
          </div>
          <hr>
          <div class="form-floating  {{(forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].invalid)?'invalid-form-group':''}}">
              <input type="email" class="form-control" formControlName="email" placeholder="name@example.com">
              <label for="floatingInput">Email address</label>
                <div class="feedback"  *ngIf="forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].hasError('pattern')">{{validationMessage.INVALID_EMAIL}}</div>
                   <div class="feedback "  *ngIf="forgetPasswordForm.controls['email'].dirty && forgetPasswordForm.controls['email'].hasError('required')">{{validationMessage.REQUIRED}}</div>

            </div>
          <div class="auth-btn">
              <button class="btn primary w-100" type="submit"  [disabled]='forgetPasswordForm.invalid'>Send Instructions</button>
          </div>
      </form>
      </div>
    </div>
  </div>
 </div> 

 <!-- <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="changePassword">
  <div class="login-page">
 <div class="col-md-4 container" >
  <form [formGroup]="changePasswordForm" (submit)="changePasswordForm.valid && changePass()" >
      <div class="sign-in-heading">
          <h3 class="">Reset Password</h3>
          <p class="auth-short-des w-100">Your new password must be different from previously used passwords.</p>
      </div>   
      <div class="form-floating">
          <input type="number" class="form-control" formControlName="code" placeholder="Otp" >
            <label for="floatingInput">OTP</label>
          <div class="feedback"
          *ngIf="changePasswordForm.controls['code'].dirty &&
          changePasswordForm.controls['code'].hasError('required')">{{validationMessage.REQUIRED }}</div>
      </div>
      <div class="form-floating">
          <input [type]="password2" class="form-control" formControlName="new_password" placeholder="New Password" >
          <a class="show-password"><img *ngIf ="show1" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password" (click)="Click('password2')"></a>
      <a class="show-password"><img *ngIf ="!show1"src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password" (click)="Click('password2')"></a>
          <label for="floatingInput">New Password</label>
          <div class="feedback"
          *ngIf="changePasswordForm.controls['new_password'].dirty &&
          changePasswordForm.controls['new_password'].hasError('required')">{{validationMessage.NEW_PASSWORD_REQUIRED}}</div>
      </div>
      <div class="form-floating">
          <input [type]="password3" class="form-control" formControlName="confirm_password" placeholder="Confirm New Password" >
          <a class="show-password"><img *ngIf ="show2" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password" (click)="Click('password3')"></a>
      <a class="show-password"><img *ngIf ="!show2" src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password" (click)="Click('password3')"></a>
          <label for="floatingInput">Confirm New Password</label>
          <br>
          <div *ngIf="checkPassSame() && !changePasswordForm.controls['confirm_password'].hasError('required')  ">
              <p class="feedback">{{passmsg}} </p>
            </div> 
      </div>
      <div class="auth-btn">
          <button class="btn admin-btn modal-btn-height w-100" type="submit" [disabled]='changePasswordForm.invalid '>Save Changes</button>
      </div>
  </form>
</div>
</div>
</div> -->



<!-- <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="passwordCreated">
  <div class="login-page">
<div class="auth-wrapper" >
  <div class="row auth-row">
      <div class="col-md-6 authbanner">
          <img class="auth-pic" src="../../../../assets/images/kindi-logo-black.png" alt="Auth pic" width="150" height="60"/>
      </div>
      <div class="col-md-6 form-wrap">
          <form class="text-center">
              <div class="email-sent text-center">
                  <img src="../../../../assets/icons/email-sent-icon.svg" alt="Email sent" />
              </div>
              <div class="sign-in-heading">
                  <h3 class="">Password created successfully</h3>
                  <p class="email-short-instruction">You have successfully created your new password Please, use your new password when signing in </p>
              </div>
            <div class="auth-btn">
                  <button class="btn primary w-100" type="submit"><a [href]="['/admin/login']">Back to sign in</a></button>
              </div> 
          </form>
      </div>
  </div>
</div>
</div>
</div> -->
