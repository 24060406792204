import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VALIDATION_MESSAGE } from 'src/app/enum/constraints';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { AuthHttpServiceService} from '../services/auth-http-service.service';
import { AuthService } from '../../../core/auth.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  host: { '[attr.id]': '"delete-account-component-id"' }
})
export class DeleteAccountComponent implements OnInit {
  error = false;
  login= false;
  data;
  isUserLoggedIn = false;
  show = false;
  isLoggedIn: boolean;
  validationMessage = VALIDATION_MESSAGE;
  deleteaccount: UntypedFormGroup;
  constructor(
    private readonly formBuilder: UntypedFormBuilder ,
    public modalRef: BsModalRef,
    private ref: ChangeDetectorRef,
    public readonly service:DashboardServiceService ,
    public modalService: BsModalService,
    private readonly router: Router,
    public readonly authService: AuthHttpServiceService, 
    public commonService: CommonService, 
    private readonly toastService: ToastService,
    private readonly AuthService: AuthService 
    ) { 
    this.isLoggedIn = AuthService.isUserLoggedIn();
    }

    ngOnInit(): void {
  
    }


    deleteUserAccount(){
      if (this.data) {
        this.service.deleteaccountuser(this.data)
          .subscribe(
            (response) => {
              // Handle successful response
              this.signout(); 
            },
          );
      }
    }

    signout() {
      localStorage.clear();
      this.isUserLoggedIn = false;
      this.router.navigateByUrl('/');
      location.reload();
    }

}
