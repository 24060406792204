import { ViewportScroller } from "@angular/common";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef, } from '@angular/core';
import { debounceTime} from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthHttpServiceService} from '../../landing-page/services/auth-http-service.service';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../../core/auth.service';
import { SuggestionsComponent } from "../suggestions/suggestions.component";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sellected-page',
  templateUrl: './sellected-page.component.html',
  styleUrls: ['./sellected-page.component.scss']
})
export class SellectedPageComponent implements OnInit {
  @ViewChild('alertMessage', { static: false }) alertMessage: ElementRef;
  showProductonFirstSCreen = [];
  loader = false;
  previousPerfume = false;
  searchData: any;
  productOffset = 0;
  searchQueries = [];
  more5: boolean = false;
  noDataFirstTime: boolean;
  haveMoreData;
  showArrow = true;
  clickperfume = false;
  perfumediv = false;
  mycollectionarray = [];
  searchInputControl = new UntypedFormControl('');
  constructor(public service: DashboardServiceService,
    private readonly AuthService: AuthService,
    private ref: ChangeDetectorRef,
    public modalService: BsModalService,
    private readonly toastService: ToastService, 
    private scroller: ViewportScroller,
    private router: Router,
    public commonService: CommonService,
    private readonly authHttpService: AuthHttpServiceService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly DashboardServiceService: DashboardServiceService,
    public modalRef: BsModalRef,
    private titleService: Title
      ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('البحث - Dona AI');
    this.searchInputControl.valueChanges.pipe(
      debounceTime(500),
    ).subscribe(val => {
      if (val !== '') {
        this.productSearch(val)
        this.perfumediv = true;
      }
    });

      /* show the product screen */
      if (this.showProductonFirstSCreen) {
        this.showProductonFirstSCreen = this.showProductonFirstSCreen;
        if (this.showProductonFirstSCreen.length > 2) {
          // this.showTheNext = true;
        }
      }
  }

  closemodel(){
    this.previousPerfume = !this.previousPerfume;
  }

  removeFromFavorites(id: any) {
    this.showProductonFirstSCreen = this.showProductonFirstSCreen.filter(item => item._id !== id);
  }



  nextscreenfirst(showProductonFirstSCreen) {
    this.router.navigate(['ar/recommendations'], { 
      state: { 
        data: showProductonFirstSCreen 
      } 
    });
  }

  add(data) {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    const maxAllowed = 5;
    let showProductonFirstSCreen = JSON.parse(localStorage.getItem('showProductonFirstSCreen')) || [];

    const exceededMax = showProductonFirstSCreen.length >= maxAllowed;

    if (exceededMax) {
        this.more5 = true;
        this.previousPerfume= true;
        if (showProductonFirstSCreen.findIndex((x) => x._id === data._id) !== -1) {
            showProductonFirstSCreen = showProductonFirstSCreen.filter((x) => x._id !== data._id);
        }
    } else {
        if (showProductonFirstSCreen.findIndex((x) => x._id === data._id) === -1) {
            showProductonFirstSCreen.push(data);
        } else {
            showProductonFirstSCreen = showProductonFirstSCreen.filter((x) => x._id !== data._id);
        }
    }

    localStorage.setItem('showProductonFirstSCreen', JSON.stringify(showProductonFirstSCreen));

    this.showProductonFirstSCreen = showProductonFirstSCreen.slice().reverse();
    this.more5 = exceededMax;
    this.ref.detectChanges();
}

  
  productSearch(search?, first?, loadMore = false) {
    this.loader = true;
    this.showArrow = false;
    if (first) {
      this.noDataFirstTime = false
    }
    else {
      this.noDataFirstTime = true
    }

    let searchTrim = search?.trim();
    let limit = 10;
    let offset = loadMore ? this.productOffset + limit : 0;
    this.authHttpService
      .widgetProduct(searchTrim, offset, limit)
      .subscribe((response) => {

        this.searchQueries.push(searchTrim);

        if (loadMore) {
          this.searchData = this.searchData.concat(response);
        } else {
          this.searchData = response;
        }
        if (this.searchData) {
          this.loader = false;
        }

        this.haveMoreData = !!response.length

        this.productOffset = offset; 
      });
  }


  idGetter(id) {
    let value
    let click  = document.querySelector('.perfume-listing-wrap')
    if (this.showProductonFirstSCreen.length != 0) {
      for (let j = 0; j <= this.showProductonFirstSCreen.length; j++) {
        if (this.showProductonFirstSCreen[j]?._id == id) {
          value = true;      
        }

      }  

    return value
  }
}

  /*remove the perfume*/  
  remove(i, storage?) {
    if (storage) {
      if (this.showProductonFirstSCreen) {
        this.showProductonFirstSCreen = this.showProductonFirstSCreen.filter(x => x._id !== i);
      }
      this.showProductonFirstSCreen = this.showProductonFirstSCreen.filter(x => x._id !== i);
      localStorage.setItem('showProductonFirstSCreen', JSON.stringify(this.showProductonFirstSCreen));

      this.mycollectionarray = this.showProductonFirstSCreen;

    }

  else {
      this.showProductonFirstSCreen = this.showProductonFirstSCreen.filter(x => x._id !== i);

    }

  }

  openSuggest(){
    this.modalRef = this.modalService.show(SuggestionsComponent, {
      initialState: {
      },
      class: 'perfume-edit-modal'
    });
  }

}
