<app-navbar></app-navbar>
<div class="product-page">
  <aside class="sidebar">
    <h6>Recommendations filter </h6>
      <!-- <p (click)="clearClick()">Rest</p> -->
    <ul >
      <li>
        <div class="filter-options" (click)="handleClick('Discount')">
          <img class="click" alt="icon" [src]="isClicked === 'Discount' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>Discount</p>
        </div>
      </li>
      <li>
        <div class="filter-options" (click)="handleClick('LowPrices')">
          <img class="click" alt="icon" [src]="isClicked === 'LowPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>Price (low to high)</p>
        </div>
      </li>
      <li>
        <div class="filter-options" (click)="handleClick('HighPrices')">
          <img class="click" alt="icon" [src]="isClicked === 'HighPrices' ? '../../../../assets/images/click-btn.png' : '../../../../assets/images/click.png'">
          <p>Price (high to low)</p>
        </div>
      </li>
      <li>
      </li>
            
                  </ul>
  </aside>
<section class="info-perfume">
  <div class="padme-perfume">
  <div class="container  col-lg-6 feature-container padme-perfume">
    <div class="row installation-heading search-list-nota-nota row feature-row">
      <div>
            <div class="padme">
              <div class="column col1">
                <img class="perfume-img info-img perfume-img-info left"   
                src="{{this.image}}"   alt="Perfume2" />
                </div>
                <div class="column col2 m-3">
                  <h1> {{this.name }}</h1>
                  <h3 class="h3-info">{{this.brand }}</h3>
                  <div class="two-price">
                    <p class="perfume-subtitle price prises with-sale" *ngIf="sale">
                      <span class="original-sale">{{ price }} SAR</span>
                      <span class="original-price">{{ sale }} SAR</span>  
                    </p>
                    <p class="perfume-subtitle price price-without-sale" *ngIf="!sale">
                      <span class="price">{{ price }} SAR</span>
                    </p>
                  </div>
                
                 <div class="d-flex">
                  <button class="btn primary pl-25 pr-25 mb-10 height40" (click)="clickuser($event)" #specificButton>
                      <a id="productLink" href="{{this.link}}" target="_blank"> <span> Get it now  <span id="websiteName"></span></span> </a>
                </button>
                </div>
                </div>      
            </div>
            
          </div>
          
      </div> 
      </div> 
        <div class="container  col-lg-8 feature-container padme-perfume">   
          <hr>
          <div class="filter-btn">
            <div>
              <h3 class="mobile-filter">Compare prices and choose the best</h3>
            </div>
          </div>
          <div dir="ltr" class="top-recommendations row">
            <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of priceDatanew;let i = index">
            <div class="" >
              <div class="perfume-pic perfume-pic-store">
                  <img class="perfume-img info-img perfume-img-info"   
                  src="{{this.image}}"   
                  alt="Perfume2" />
                </div>
              <div class="perfume-des">
              <div class="nota-info">
                <div class="nota-info-2">
                  <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                    <span class="perfume-title"  >{{data["name"]}}</span>
                  </div>
                  <div class="nota-nota-tooltip-hover">
                    <span class="perfume-subtitle">{{data["brand"]}}</span>
                  </div>
              
              </div>
              <div class="nota-nota-tooltip-hover">
              <div class="two-price">
                <p class="perfume-subtitle price prises with-sale" *ngIf="price && data['sale'] && data['sale'] !== data['price']">
                  <span class="original-sale">{{ extractNumber(data['price']) | number: '1.2-2' }} SAR</span>
                  <span class="original-price">{{ extractNumber(data['sale']) | number: '1.2-2' }} SAR</span>
                </p>
                <p class="perfume-subtitle price price-without-sale" *ngIf="price && (!data['sale'] || data['sale'] === data['price'])">
                  {{ extractNumber(data['price']) | number: '1.2-2' }} SAR
                </p>
              </div>
              </div>
              </div>
              </div>
              <div class="nota-info">
                <div class="nota-nota-tooltip-hover store">
                  <a><img class=""  
                      src="{{data['logo']}}"  alt="Perfume2" /></a>
                  </div>
                <button  class="add-to-cart btn primary pl-25 pr-25" (click)="Perfumeinfo(data,data['link'])"> <a  target="_blank" href="{{data.link}}"> Get it now</a></button>
              </div>
            </div>
            </div>             
            </div>
          </div>
        </div>
  
          <app-footer></app-footer>
      

  <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->