<app-navbar></app-navbar>
<section id="installation">
  <div class="installation desktop">
    <div class="installation-container">
      <div class="row installation-heading search-list-nota-nota row feature-row">
        <div>
        <form class=" container">
        <div class="form-group mb-20 container">
            <p *ngIf="clickperfume">Pick at least 3 favorites to unlock your match!</p>
            <h3>Search for your favorite fragrances</h3>
            <input dir="ltr" type="search" placeholder="Search by perfume name" [formControl]="searchInputControl" />
            <!-- <img class="search-cross" src="/assets/icons/search-cross.svg" alt="Search cross" (click)="crossSearchScreen()" /> -->
          </div>
         <div class="div-perfume" *ngIf="perfumediv">
          <h3>Add <span class="highlight"> 3 to 5</span> of your favorite perfumes </h3>
          <!-- <h3 #alertMessage class="alert-message" *ngIf="more5"> You can add only 5 perfumes </h3> -->

          <hr/>
          <div dir="ltr" class="perfume-listing container">
            <div dir="ltr" class="perfume-listing-wrap "
              *ngFor="let data of searchData" (click)="add(data)"  >
              <div dir="ltr" class="perfume-info">
                <div class="perfume-pic">
                  <img class="right-arrow" src="{{data?.image_url}}"  onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/default.png';"  alt="Perfume pic" />
                </div>
                <div class="perfume-des">
                  <h6 class="perfume-title"  >{{data?.name}} </h6>
                  <p class="perfume-text">{{data?.brand}}</p>
                </div>
              </div>
            <div class="plus-icon">
              <button class="btn add-to-cart pl-25 pr-25" [ngClass]="idGetter(data?._id)? 'disableClass' : '' ">
                {{ idGetter(data?._id) ? 'Added' : ' Add' }}
              </button>
            </div>

            
            </div>  
            <div class="spinner-nota-nota" *ngIf="loader">
              <div class="loader-nota-nota"></div>
              <div class="loader-overlay-nota-nota"></div>
            </div>

            <div class="width-100">
              <div class="recommended-null" *ngIf="!loader">
                <div class="no-perfume-found request-this-perfume" *ngIf="searchData?.length ===0 && noDataFirstTime">
                  <h5 class="no-title">Oops... no perfume found</h5>
                  <p class="no-text">Seems like this perfume is not in our list yet.</p>
                  <button class="no-btn btn primary pl-25 pr-25"
                     (click)="openSuggest()">Suggest Perfume</button>
                </div>
              </div>
            </div>
            
          </div>
          <div class="button-container">
            <button class="mb-50" (click)="nextscreenfirst(showProductonFirstSCreen)" 
                    [disabled]="!(showProductonFirstSCreen.length > 2)"
                    [ngClass]="{'black-button': showProductonFirstSCreen.length > 2, 'disabled-button': showProductonFirstSCreen.length <= 2}">
            Next
            </button>
          </div>
          
        </div>
        </form>
      </div>
  
      </div>
    </div>
  </div>

  <div class="login-modal-nota-nota after-login-modal-transparent" *ngIf="previousPerfume">
    <div class="login-page">
      <img  (click)="closemodel()"
      src="/assets/icons/close-icon.svg" alt="Close icon" />
      <h2> 5 Perfume Added</h2>
       <h6>You can add up to 5 fragrances only, Please remove one  </h6>
       <hr>
      <div class="login-body">
          <div dir="ltr" class="top-recommendations row mt-3 mb-5">
                <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of showProductonFirstSCreen;let i = index">
                  <div class="" >
                  <div class="perfume-pic perfume-pic-store">
                      <img class="perfume-img info-img perfume-img-info"   
                      src="{{data.image_url}}" 
                      alt="Perfume2" />
                    </div>
                  <div class="perfume-des">
                    <div class="nota-info-2">
                      <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                        <span class="perfume-title"  >{{data["name"]}}</span>
                      </div>
                      <div class="nota-nota-tooltip-hover">
                        <span class="perfume-subtitle">{{data["brand"]}}</span>
                      </div>
      <hr>
    
                  </div>
                  </div>
                  <div class="nota-info">
                    <button  class="removebtn btn primary pl-25 pr-25" (click)="removeFromFavorites(data._id)"> Remove </button>
                  </div>
                </div>
                
                </div>   

        </div>
</div>
</div>
</div>



  <!-- <app-chat-widght></app-chat-widght> -->
  <app-footer></app-footer>

    <!-- Choose your fragrance, Select your perfume, Select your fragrance, Best perfume choice, Perfumes best seller, Niche perfumes, Perfumes stores, Fragrance stores, Perfumes technology, Perfumes recommendations, Perfumes recommendations system, AI for perfumes -->
  <!-- Perfume assistant, choose perfume, Choose fragrance, Select perfume, Select fragrance, Best perfume choice, perfumes best seller, niche perfumes, perfumes stores, Fragrance stores, perfumes technology, perfumes recommendations, perfumes recommendations system, AI for perfumes -->