<app-navbar></app-navbar>
<div class="product-page" lang="ar">
<section class="info-perfume">
  <div class="padme-perfume">
        <div class="container  col-lg-8 feature-container padme-perfume">   

          <div dir="ltr" class="top-recommendations row">
            <div class="our-recommended-nota-nota-other col-md-3" *ngFor="let data of ingredient;let i = index">
            <div class="" >
              <div class="perfume-pic perfume-pic-store">
                  <img class="perfume-img info-img perfume-img-info"   
                  src="{{data.image_url}}"   
                  alt="Perfume2" />
                </div>
              <div class="perfume-des">
              <div class="nota-info">
                <div class="nota-info-2">
                  <div class="nota-nota-tooltip-hover"  [ngClass]=" data['name'] ? '' : 'widget-pointer'">
                    <span class="perfume-title"  >{{data.name_ar}}</span>
                  </div>
                  <div class="nota-nota-tooltip-hover">
                    <span class="perfume-subtitle">{{data.brand_ar}}</span>
                  </div>
              
              </div>
              </div>
              </div>
            </div>
            </div>             
            </div>
          </div>
        </div>
          <app-footer></app-footer>
      