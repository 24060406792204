<app-navbar></app-navbar>
<div class="auth-wrapper" *ngIf="main">
  <div class="col-md-4 form-wrap container">
    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
      <div class="sign-in-heading">
        <h3 class="">Reset Password</h3>
        <hr>
        <p class="auth-short-des w-100">Your new password must be different from previously used passwords.</p>
      </div>
      <hr> 
      <p *ngIf="errorMessage" class="error">New password must be strong with 11 characters and include special characters</p>
      <div class="form-floating">
        <input [type]="password2" class="form-control" formControlName="new_password" placeholder="New Password">
        <a class="show-password">
          <img *ngIf="show1" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password" (click)="onClick('password2')">
        </a>
        <a class="show-password">
          <img *ngIf="!show1" src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password" (click)="onClick('password2')">
        </a>
        <label for="floatingInput">New Password</label>
        <div class="feedback"
          *ngIf="changePasswordForm.controls['new_password'].dirty && changePasswordForm.controls['new_password'].hasError('required')">
          {{validationMessage.NEW_PASSWORD_REQUIRED}}
        </div>
      </div>
      <div class="form-floating">
        <input [type]="password3" class="form-control" formControlName="confirm_password" placeholder="Confirm New Password">
        <a class="show-password">
          <img *ngIf="show2" class="show-password" src="../../../../assets/icons/eye-icon.svg" alt="Show password" (click)="onClick('password3')">
        </a>
        <a class="show-password">
          <img *ngIf="!show2" src="../../../../assets/icons/hide-eye-icon.svg" alt="Hide password" (click)="onClick('password3')">
        </a>
        <label for="floatingInput">Confirm New Password</label>
        <br>
        <div *ngIf="checkPassSame() && !changePasswordForm.controls['confirm_password'].hasError('required')">
          <p class="feedback">{{passmsg}}</p>
        </div>
      </div>
      <div class="auth-btn">
        <button class="btn admin-btn w-100" type="submit">Save Changes</button>
      </div>
    </form>
  </div>
</div>

<div class="auth-wrapper" *ngIf="passwordCreated">
  <div class="col-md-4 form-wrap container">
      <form class="text-center">
        <div class="email-sent text-center">
          <img src="../../../../assets/icons/email-sent-icon.svg" alt="Email sent" />
        </div>
        <div class="sign-in-heading">
          <h3 class="">Password created successfully</h3>
          <hr>
          <p class="email-short-instruction">You have successfully created your new password. Please, use your new password when signing in.</p>
        </div>
        <div class="auth-btn">
          <button class="btn primary w-100" type="button">
            <a [routerLink]="['/']">Back to sign in</a>
          </button>
        </div>
      </form>
    </div>
  </div>
<app-footer></app-footer>
