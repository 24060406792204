import { Component, Input, OnInit } from '@angular/core';
import { delete_reason } from '../../../enum/constraints';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DashboardServiceService } from '../../authenticate/dashboard-service.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-delete-product',
  templateUrl: './delete-product.component.html',
  styleUrls: ['./delete-product.component.scss']
})
export class DeleteProductComponent implements OnInit {
  delete_reason = delete_reason.sort();
  userid = localStorage.getItem("user_id");
  deleteform: any;
  product_id: any;
  @Input() data: any;
  constructor(
    private readonly formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    public service: DashboardServiceService
  ) { }
  formInitialization() {
    this.deleteform = this.formBuilder.group({
      reason: ['', [Validators.required]],
    }
    );
  }
  ngOnInit(): void {
    this.formInitialization();
  }
  deleteProsuct(){
  const data = {
    "user_id": this.userid,
    "reason": this.deleteform.value.reason,
    "product_id": this.data._id,
  }
  this.service.delete_product_user_list(this.userid, data).subscribe(
    response => {
    this.modalRef.hide()
    window.location.reload();
    },
    error => {

    }
  )
  }
}
