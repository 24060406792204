
<app-navbar></app-navbar>
<div class="body" lang="ar">
  <div *ngIf="isLoading" class="loader-overlay">
    <div class="loader"></div>
  </div>
<section>
  <div class="container feature-container" >

        <div class="containers">
          <div class="text p-4">
            <h4>
              <!-- اهلا {{this.name }} هنا  -->
              ملفك العطري</h4>
            <p>
                انت تحب
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 0">
                    <span class="words">{{ mostFrequentMainNotes[0] }}</span>
                </span>
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 1">
                    و
                    <span class="words">{{ mostFrequentMainNotes[1] }}</span>
                </span>
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length > 1">
                من عائلات العطور
                </span>
                <span *ngIf="mostFrequentMainNotes && mostFrequentMainNotes.length <= 1">
                  من عائلات العطور
                </span>
              مع
                <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 0">
                    <span class="words">{{ mostFrequentSubNotes[0] }}</span>
                </span>
                <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length > 1">
                    و
                    <span class="words">{{ mostFrequentSubNotes[1] }}</span>
                </span>
                <span *ngIf="mostFrequentSubNotes && mostFrequentSubNotes.length <= 1"></span>
            </p>
          </div>
          <div>
            <h6>النوتات المفضلة</h6>
            <div class="box-div">
              <div *ngFor="let data of noteItemDetails | slice:0:10">
                  <button  class="btn notes-box primary pl-25 pr-25" >{{ data.name_ar }} </button>
              </div>
            </div>
          </div>
          </div>                
          </div>
</section>

<section>
  <div class="container feature-container">
    <h4> القائمة الخاصة بي</h4>
    <div class="top-recommendations  col-md-3 row mt-3 mb-4">
      <div class="our-recommended-nota-nota" *ngFor="let data of Products; let i = index">
        <img (click)="addFavorites(data)" class="fav-img-rec m-lg-2 mobile-fav"
        [src]="'../../../../assets/images/love.png'" />
        <div class="perfume-pic">
          <img class="perfume-img"
               src="{{data.image_url}}"
               onerror="this.src='https://ntoa-nota-public.s3.us-west-2.amazonaws.com/widget/assets/images/perfume2.png';"
               alt="Perfume2" />
        </div>
        <div class="perfume-des">
          <div class="nota-info">
            <div class="nota-info-2">
              <div class="nota-nota-tooltip-hover">
                <h6 class="perfume-title">{{data.name}}</h6>
              </div>
              <div class="nota-nota-tooltip-hover">
                <p class="perfume-subtitle">{{data.brand}}</p>
              </div>
            </div>
          </div>
          <hr>
          <div class="nota-info">
            <button (click)="openModal(data)" class="add-to-cart btn primary pl-25 pr-25" > حذف  العطر</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



</div>


<app-footer></app-footer>




